import { GlobalSearchParams, GlobalSearchResults } from '../../services/api/apiTypes'
import { gql } from 'graphql-request'

export const queryGlobalSearch = (params: GlobalSearchParams): string => {
  return gql`
    {
      userEntities(
        first: 50,
        after: "",
        filter: [
          { key: NAME, values: ["${params.value}"] }
        ]
      ) {
        edges {
          node {
            id
            name
            address
            type {
              edges {
                node {
                  name
                }
              }
            }
            dynamicPiiData
            documentsCount
            riskStatus
            state
            deletedUserEntityReferences
          }
        }
    	}
    }
  `
}

const HARD_CODED_ENTITY_LABEL = 'entity'

export const mapGlobalSearchResults = (raw: any): GlobalSearchResults => {
  try {
    const userEntities = raw.userEntities.edges.map((edge: any) => {
      return {
        id: edge.node.id,
        name: edge.node.name,
        address: edge.node.address,
        type: edge.node.type.edges[0].node.name,
        dynamicPiiData: edge.node.dynamicPiiData,
        documentsCount: edge.node.documentsCount,
        riskStatus: edge.node.riskStatus,
        state: edge.node.state,
        deletedUserEntityReferences: edge.node.deletedUserEntityReferences
      }
    })

    const results: GlobalSearchResults = {
      labels: [HARD_CODED_ENTITY_LABEL],
      results: userEntities
    }

    return results
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    return {
      labels: [],
      results: []
    }
  }
}
