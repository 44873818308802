import {
  mapQueryTopAttributeInstances,
  queryTopAttributeInstances,
  queryAttributesSetList,
  queryAttributesSetCard,
  mapQueryAttributesSet,
  queryAttributesAllCard,
  queryAttributesTopWidget,
  mapQueryAttributesTopWidget,
  mapQueryAttributesAll,
  queryMutationCreateAttributeSet,
  queryAttributeCards,
  queryAttributesTable,
  mapQueryAttributesListing,
  mapQueryAttributeNames,
  queryAttributeNames,
  queryAttributeNamesIds,
  mapQueryAttributeNameIds,
  mapQueryAttributesListAll,
  queryAttributeslistAll,
  queryListAttributesTable,
  mapQueryDataSourceAttributesTable,
  queryDriveAttributesTopWidget,
  mapQueryDriveAttributesTopWidget,
  queryMutationSetAttributeSetActive,
  mapQueryMutationCreateAttributeSet,
  mapQueryAttributeInstancesCount,
  queryAttributeInstancesCount,
  queryAttributesSetWithAttributes,
  mapQueryAttributesSetWithAttributes,
  queryAttributesSetListCompact,
  mapQueryAttributesSetListCompact,
  queryMutationUpdateAttributeSet,
  queryAwsAttributesTable,
  mapQueryAwsAttributesTable,
  queryAwsTopAttributeInstances,
  queryDeleteAttributesSet,
  queryDatasourceAttributeCards,
  mapQueryDatasourceAttributeCards,
  querySlackAttributeSummary,
  mapQuerySlackAttributeSummary,
  queryAttributeTypeSummary,
  mapQueryAttributeTypeSummary,
  queryAttributesSetSummaryWithAttributes,
  mapQueryAttributesSetSummaryWithAttributes,
  queryAttributeCondition,
  mapQueryAttributeCondition,
  queryAttributesTableSensitivitySorted,
  mapQueryAttributesListingSensitivitySorted,
  mapQueryBlobAttributeCards,
  queryBlobAttributeCards,
  queryBlobAttributeInstancesCount,
  mapQueryBlobAttributeInstancesCount,
  queryListBlobAttributesTable,
  queryAttributesAccess,
  mapQueryAttributesAccess,
  queryAttributeDataSources,
  mapQueryAttributeDataSources,
  mapQueryAttributeUserAccess,
  queryAttributeUserAccess,
  queryAttributeUserAccessGroupByDepartment,
  mapQueryAttributeUserAccessGroupByDepartment,
  queryAttributeGroupAccess,
  mapQueryAttributeGroupAccess,
  createCustomAttributeMutation,
  queryCustomAttributes,
  mapQueryCustomAttributes,
  queryCustomAttributeById,
  mapQueryCustomAttributeById,
  updateCustomAttributeMutation,
  assignAttributeSetsMutation,
  queryAttributesForOnDemandScanning,
  mapQueryAttributesForOnDemandScanning
} from './queries'
import {
  CustomAttribute,
  CustomAttributeData,
  CustomAttributeListItem,
  RegexValidationResponse
} from './settings/types'
import {
  ENTITY_ID,
  PAGE,
  DATA_SOURCE_TYPES,
  ATTRIBUTE_SET_TYPES,
  FILTER_ATTRIBUTE_DATASOURCEIDS_KEY,
  ALERT_ID,
  DATA_SOURCE_ID,
  FILTER_ATTRIBUTE_HAS_INSTANCES,
  CLASSICATION_TYPE,
  DRIVE_ID,
  PROJECT_ID,
  DATA_SOURCE_TYPE,
  BUCKET_ID,
  API_PARAM_ATTRIBUTE,
  SEVERITY_LEVEL,
  FILTER_CLASS_KEY,
  FILTER_RETAIN_UNTIL,
  FILTER_SENSITIVITY,
  GRAPHQL_API_FILTERS,
  SENSITIVE_LABEL,
  PAGE_SIZE,
  FILTER_MAIL_FOLDER_TYPE
} from '../../constants'
import { FilterParams } from '../../interfaces'
import graphqlService from '../../services/graphqlService'
import { defaultSortParams, getSortDirection, SortParams } from '../../utils/sortUtil'
import { AlertAttributeCondition } from '../policies/policiesSlice'
import { RootState } from '../../rootReducer'
import { getGlobalParams } from '../../utils/urlUtil'
import {
  AccessControlUserGroupByDepartmentItem,
  DataSensitivityLevels
} from '../accessControl/types'
import apiService from '../../services/api/apiService'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export type SetAttribute = {
  id: string
  name: string
  sensitivityLabel: string
  owner: string
  attributeSetId: string
  isSelected: boolean
  attributeSetsCount: number
}
export type SetWithAttributes = {
  id: string
  name: string
  type?: ATTRIBUTE_SET_TYPES
  attributesCount: number
  isSelected: boolean
  isExpanded: boolean
  attributes: SetAttribute[]
}

export type AttributeWidgetByEntityItem = {
  name: string
  internalName: string
  count: number
}

export type AttributeDataSource = {
  id: string
  name: string
  type: DATA_SOURCE_TYPES
}

export type AttributeGrouped = {
  attributeName: string
  internalName: string
  objectsCount: number
  ticketsCount: number
  dataSourcesCount: number
  sensitivityLabel: string
  attributeInstanceCount: number
  dataSources: AttributeDataSource[]
  rowCount: number
  userAccessCount?: number
}

export type Attribute = {
  attributeId: string
  attributeName: string
  attributeValue: string
  objectId: string
  objectName: string
  fileClassification?: string
  identifier: string
  dataSourceId: string
  dataSourceName: string
  dataSourceType: DATA_SOURCE_TYPES
}

export interface AttributeDataSourceInfo extends AttributeDataSource {
  attributesInstanceCount: number
  owner: string
  groupAccessCount?: number
  userAccessCount?: number
}

export interface AttributeUserAccess {
  id: string
  name: string
  employeeType: string
  dataPrivilegeLevel: DataSensitivityLevels
  departmentName?: string
  dataSourcesCount?: number
}

export interface AttributeGroupAccess {
  id: string
  name: string
  usersCount?: number
  groupsCount?: number
  dataPrivilegeLevel: DataSensitivityLevels
  dataSourcesCount?: number
}

export type AttributeSetAttribute = {
  owner?: string
  id: string
  name: string
  isBeta?: boolean
  sensitivityLabel?: string
  systemDefined?: boolean
  instanceCount: number
  setId?: string
  risk?: string
  dataSources?: Array<{ id: string; name: string }>
  datasources?: DataElementDataSource[]
}
export interface DataElementDataSource {
  id: string
  type: string
  name: string
  count?: number
}

export type AttributeSet = {
  id: string
  name: string
  enabled: boolean
  systemDefined?: boolean
  type?: ATTRIBUTE_SET_TYPES
  description?: string
  owner?: string
  attributesInstanceCount?: number
  attributesCount?: number
  dataSourcesCount?: number
  attributes?: AttributeSetAttribute[]
  dataSources?: AttributeDataSourceInfo[]
}

export type AttributeSetDataSource = {
  attributesInstanceCount: number
  userAccessCount?: number
  groupAccessCount?: number
  owner: string
  name: string
  type: DATA_SOURCE_TYPES
}

export type AttributeItem = {
  id: string
  name: string
  isBeta: boolean
  enabled: boolean
  types: ATTRIBUTE_SET_TYPES[]
  attributesInstanceCount?: number
  partnerAttributeInstanceCount?: number
  attributeSetsCount?: number
  sensitivityLabel?: string
  owner?: string
  dataSourcesCount?: number
  attachmentsCount?: number
  channelsCount?: number
  dataSources?: AttributeDataSourceInfo[]
  columnCount?: number
  attributeId?: string
  ticketName?: string
  projectName?: string
  internalName?: string
  messageId?: string
  attributeSets?: AttributeSet[]
  description?: string
  identifier?: string
  channelName?: string
  channelId?: string
  updateTimestamp?: number
  fileName?: string
  fileClassification?: CLASSICATION_TYPE
  fileMetadataId?: string
  fileId?: string
  mailFrom?: string
  mailTo?: string[]
  subject?: string
  sharedWhen?: string
  detectedWhen?: string
  ticketId?: string
  ticketJiraId?: string
  objectId?: string
  objectLink?: string
  rowId?: string
  attributeName?: string
  attachments?: { type: string; name: string; id: string }[]
  alerts?: { name: string; id: string; severity: SEVERITY_LEVEL }[]
  tableId?: string
  actionTaken?: string
  dataSourceId?: string
  dataSourceName?: string
  dataSourceType?: DATA_SOURCE_TYPES
  userAccessCount?: number
  groupAccessCount?: number
  sharedBy?: string
  objectEntities?: { id: string; name: string }[]
}

export type AttributeCardItem = {
  id: string
  name: string
  types: ATTRIBUTE_SET_TYPES[]
}

export type AttributesListSettings = {
  list?: Attribute[]
  total?: number
  sort: SortParams
}
export type AttributesSetSettings = {
  list?: AttributeSet[]
  cards?: AttributeSet[]
  total?: number
  sort: SortParams
}

export interface AttributeNameIds {
  name: string
  internalName: string
  id: string
  enabled: boolean
}
export interface AttributeListAll {
  name: string
  internalName?: string
  id: string
  attributesInstanceCount?: number
  types: ATTRIBUTE_SET_TYPES[]
}
export type AttributesTopListByDataSource = {
  datasourceId: string
  list: AttributeItem[]
  isHighSensitive?: boolean
}
export type AttributesAllSettings = {
  list?: AttributeItem[]
  topList: AttributesTopListByDataSource[]
  highSensitiveTopList: AttributesTopListByDataSource[]
  cards?: AttributeItem[]
  total?: number
  instancesCount?: number
  sort: SortParams
  names?: string[]
  nameIds?: AttributeNameIds[]
  listAll?: AttributeListAll[]
  listAllTotal?: number
  dataSources?: AttributeDataSourceInfo[]
  userAccess?: AttributeUserAccess[]
  groupAccess?: AttributeGroupAccess[]
  userAccessTotal?: number
  groupAccessTotal?: number
  userAccessCards?: AccessControlUserGroupByDepartmentItem[]
  userAccessCardsTotal?: number
}
const initialTopList = []

const initialAttributesList: AttributesListSettings = { sort: defaultSortParams }
const initialAttributesSetList: AttributesSetSettings = { sort: defaultSortParams }
const initialAttributesAllList: AttributesAllSettings = {
  sort: defaultSortParams,
  topList: initialTopList,
  highSensitiveTopList: initialTopList
}
export type setActiveMutationParam = {
  clientMutationId: string
  id: string
  enabled: boolean
}
export interface AttributeTypeInfo {
  name: string
  type: string
}

type OnDemandScanningAttributes = {
  attributeSetList: AttributeSet[]
  attributes: AttributeSetAttribute[]
}

type AttributesState = {
  widget?: AttributeWidgetByEntityItem[]
  cards?: AttributeGrouped[]
  list: AttributesListSettings
  slackCards?: AttributeItem[]
  attributeSets: AttributesSetSettings
  attributeTypeInfo?: AttributeTypeInfo[]
  attributes: AttributesAllSettings
  attributeSetsCreateEditSuccess: boolean
  attributeSetAttributes: {
    total?: number
    list?: SetWithAttributes[]
  }
  attributeCondition?: AlertAttributeCondition[]
  customAttributes?: CustomAttributeListItem[]
  selectedCustomAttribute?: CustomAttribute
  validationResult?: RegexValidationResponse
  onDemandScanningAttributes: OnDemandScanningAttributes
}

export const initialState: AttributesState = {
  list: initialAttributesList,
  attributeSets: initialAttributesSetList,
  attributes: initialAttributesAllList,
  attributeSetsCreateEditSuccess: false,
  attributeSetAttributes: {},
  onDemandScanningAttributes: {
    attributes: [],
    attributeSetList: []
  }
}

export type IGetAttributesParams = {
  [ENTITY_ID]?: string
  [ALERT_ID]?: string
  [PROJECT_ID]?: string
  [DATA_SOURCE_ID]?: string
  [API_PARAM_ATTRIBUTE]?: string
  [DRIVE_ID]?: string
  [BUCKET_ID]?: string
  [FILTER_ATTRIBUTE_DATASOURCEIDS_KEY]?: string[]
  [FILTER_RETAIN_UNTIL]?: string
  [FILTER_MAIL_FOLDER_TYPE]?: string
  filters?: FilterParams
  subAlertAssignees?: string[]
}

export interface IGetDatasourceAttributesParams extends IGetAttributesParams {
  [DATA_SOURCE_TYPE]?: DATA_SOURCE_TYPES
  [FILTER_SENSITIVITY]?: string
  [FILTER_CLASS_KEY]?: string
  folderId?: string
  partnerId?: string
  blobId?: string
}

export const ACTION_ATTRIBUTES_WIDGET_FETCH = 'attributes/widget'
export const fetchAttributesWidgetData = createAsyncThunk(
  ACTION_ATTRIBUTES_WIDGET_FETCH,
  async (params: IGetAttributesParams & IAttributeFilterParams) => {
    const resultRaw = await graphqlService.execute(queryTopAttributeInstances(params))
    return mapQueryTopAttributeInstances(resultRaw)
  }
)

export const ACTION_TOP_ATTRIBUTES_AWS_FETCH = 'attributes/top/aws'
export const fetchAwsTopAttributes = createAsyncThunk(
  ACTION_TOP_ATTRIBUTES_AWS_FETCH,
  async (params: IGetAttributesParams & IAttributeFilterParams) => {
    const resultRaw = await graphqlService.execute(queryAwsTopAttributeInstances(params))
    const result = mapQueryDriveAttributesTopWidget(resultRaw)
    return {
      list: result.topList,
      datasourceId: params?.filter?.find((obj) => obj.key === 'DATASOURCE_IDS')?.values[0] || ''
    }
  }
)

export type AttributeTableResponse = {
  list: AttributeItem[]
  total: number
}

export const ACTION_ATTRIBUTES_TABLE_AWS_FETCH = 'attributes/list/aws'
export const fetchAwsAttributesTable = createAsyncThunk(
  ACTION_ATTRIBUTES_TABLE_AWS_FETCH,
  async (params: IGetAttributesTableListParams & IGetAttributesFilterParams) => {
    const resultRaw = await graphqlService.execute(queryAwsAttributesTable(params))
    return mapQueryAwsAttributesTable(resultRaw)
  }
)

export type AttributesListParams = {
  [PAGE]: number
  [ENTITY_ID]?: string
  [ALERT_ID]?: string
  [DATA_SOURCE_ID]?: string
  [API_PARAM_ATTRIBUTE]?: string
  [BUCKET_ID]?: string
  [DATA_SOURCE_TYPE]?: string
}

export type IGetAttributesFilterParams = IAttributeCommonParams &
  IAttributeFilterParams & { isStructuredDS?: boolean }
export const ACTION_ATTRIBUTES_SET_FETCH = 'attributesSet/cards'
export const fetchAttributesSetCard = createAsyncThunk(
  ACTION_ATTRIBUTES_SET_FETCH,
  async (params: IGetAttributesFilterParams) => {
    const resultRaw = await graphqlService.execute(queryAttributesSetCard(params))
    return mapQueryAttributesSet(resultRaw)
  }
)

export const ACTION_ATTRIBUTES_SET_LIST_COMPACT_FETCH = 'attributesSet/list/compact'
export const fetchAttributesSetCompactList = createAsyncThunk(
  ACTION_ATTRIBUTES_SET_LIST_COMPACT_FETCH,
  async () => {
    const resultRaw = await graphqlService.execute(queryAttributesSetListCompact())
    return mapQueryAttributesSetListCompact(resultRaw)
  }
)

export type IGetAttributesSetListParams = {
  [PAGE]: number
}
export const ACTION_ATTRIBUTES_SET_LIST_FETCH = 'attributesSet/list'
export const fetchAttributesSetList = createAsyncThunk(
  ACTION_ATTRIBUTES_SET_LIST_FETCH,
  async (params: IGetAttributesTableListParams & IGetAttributesFilterParams, { getState }) => {
    const queryParams = { ...params, ...getGlobalParams(getState() as RootState) }
    const resultRaw = await graphqlService.execute(queryAttributesSetList(queryParams))
    return mapQueryAttributesSet(resultRaw)
  }
)

export const ACTION_CREATE_CUSTOM_ATTRIBUTE = 'attributes/createCustomAttribute'
export const createCustomAttribute = createAsyncThunk(
  ACTION_CREATE_CUSTOM_ATTRIBUTE,
  async (params: CustomAttributeData) => {
    await graphqlService.execute(createCustomAttributeMutation(params))
  }
)

export const ACTION_VALIDATE_REGEX = 'attributes/validateRegex'
export const validateRegex = createAsyncThunk(
  ACTION_VALIDATE_REGEX,
  async (params: { text: string; regexValues: string[]; contextWords?: string[] }) => {
    const response = await apiService.validateRegexAndContext(params)
    return response
  }
)

export type AttributeDetailsParams = {
  attributeId: string
  page?: number
  dataPrivilegeLevels?: DataSensitivityLevels[]
  departmentName?: string
  employeeType?: string
  entityId?: string
  datasourceId?: string
  entityTypes?: string[]
}
export const ACTION_ATTRIBUTE_DATA_SOURCES = 'attributes/dataSources'
export const fetchAttributeDataSources = createAsyncThunk(
  ACTION_ATTRIBUTE_DATA_SOURCES,
  async (params: AttributeDetailsParams) => {
    const resultRaw = await graphqlService.execute(queryAttributeDataSources(params))
    return mapQueryAttributeDataSources(resultRaw)
  }
)
export const ACTION_ATTRIBUTE_USER_ACCESS = 'attributes/userAccess'
export const fetchAttributeUserAccess = createAsyncThunk(
  ACTION_ATTRIBUTE_USER_ACCESS,
  async (params: AttributeDetailsParams) => {
    const resultRaw = await graphqlService.execute(queryAttributeUserAccess(params))
    return mapQueryAttributeUserAccess(resultRaw)
  }
)
export const ACTION_ATTRIBUTE_USER_ACCESS_CARDS = 'attributes/userAccessCards'
export const fetchAttributeUserAccessCards = createAsyncThunk(
  ACTION_ATTRIBUTE_USER_ACCESS_CARDS,
  async (params: AttributeDetailsParams) => {
    const resultRaw = await graphqlService.execute(
      queryAttributeUserAccessGroupByDepartment(params)
    )
    return mapQueryAttributeUserAccessGroupByDepartment(resultRaw)
  }
)

export const ACTION_ATTRIBUTE_GROUP_ACCESS = 'attributes/groupAccess'
export const fetchAttributeGroupAccess = createAsyncThunk(
  ACTION_ATTRIBUTE_GROUP_ACCESS,
  async (params: AttributeDetailsParams) => {
    const resultRaw = await graphqlService.execute(queryAttributeGroupAccess(params))
    return mapQueryAttributeGroupAccess(resultRaw)
  }
)

export const ACTION_ATTRIBUTES_SET_ACTIVE = 'attributesSet/list/active'
export const setAttributesSetActive = createAsyncThunk(
  ACTION_ATTRIBUTES_SET_ACTIVE,
  async (params: setActiveMutationParam) => {
    const resultRaw = await graphqlService.execute(queryMutationSetAttributeSetActive(params))
    return mapQueryMutationCreateAttributeSet(resultRaw)
  }
)
export type IAttributeCommonParams = {
  id?: string
  [FILTER_SENSITIVITY]?: string
  [FILTER_ATTRIBUTE_DATASOURCEIDS_KEY]?: string[]
  [FILTER_ATTRIBUTE_HAS_INSTANCES]?: boolean
  [DRIVE_ID]?: string
  [ENTITY_ID]?: string
  [ALERT_ID]?: string
  [DATA_SOURCE_ID]?: string
  [FILTER_MAIL_FOLDER_TYPE]?: string
  filters?: FilterParams
}

export type IAttributeFilterParams = {
  filter?: {
    key: string
    values: string[]
  }[]
  booleanFilter?: {
    key: string
    value: boolean
  }[]
}

export const ACTION_ATTRIBUTES_CARD_FETCH = 'new/attributes/cards'
export const fetchAttributeCards = createAsyncThunk(
  ACTION_ATTRIBUTES_CARD_FETCH,
  async (params: IGetAttributesFilterParams) => {
    const resultRaw = await graphqlService.execute(
      queryAttributeCards(params as IGetAttributesFilterParams)
    )
    return mapQueryAttributesListing(resultRaw)
  }
)

export const ACTION_LIST_CARDS_ATTRIBUTES_FETCH = 'list/attributes/cards'
export const fetchAttributesListCards = createAsyncThunk(
  ACTION_LIST_CARDS_ATTRIBUTES_FETCH,
  async (params: IGetDatasourceAttributesParams) => {
    if (params.blobId) {
      const resultRaw = await graphqlService.execute(
        queryBlobAttributeCards(params),
        ACTION_LIST_CARDS_ATTRIBUTES_FETCH
      )
      return mapQueryBlobAttributeCards(resultRaw, params[FILTER_SENSITIVITY])
    } else {
      const instancesRaw = await graphqlService.execute(
        queryDatasourceAttributeCards(params),
        ACTION_LIST_CARDS_ATTRIBUTES_FETCH
      )
      const instances = mapQueryDatasourceAttributeCards(instancesRaw, params[FILTER_SENSITIVITY])

      if (window.__featureFlags.accessControl) {
        const attributesRaw = await graphqlService.execute(queryAttributesAccess(params))
        const attributes = mapQueryAttributesAccess(attributesRaw)
        return instances.map((instance) => {
          return {
            ...instance,
            userAccessCount:
              attributes.find((attr) => attr.internalName === instance.internalName)
                ?.userAccessCount || 0
          }
        })
      }
      return instances
    }
  }
)
export const ACTION_SLACK_ATTRIBUTES_SUMMARY_FETCH = 'slack/attributes/summary'
export const fetchSlackAttributeSummary = createAsyncThunk(
  ACTION_SLACK_ATTRIBUTES_SUMMARY_FETCH,
  async (params: IGetDatasourceAttributesParams) => {
    const resultRaw = await graphqlService.execute(querySlackAttributeSummary(params))
    return mapQuerySlackAttributeSummary(resultRaw)
  }
)

export const ACTION_ATTRIBUTE_TYPE_FETCH = 'attributes/type/info'
export const fetchAttributeTypeSummary = createAsyncThunk(ACTION_ATTRIBUTE_TYPE_FETCH, async () => {
  const resultRaw = await graphqlService.execute(queryAttributeTypeSummary())
  return mapQueryAttributeTypeSummary(resultRaw)
})

export const ACTION_ATTRIBUTES_FETCH = 'attributes/listAll/card'
export const fetchAttributesListAllCard = createAsyncThunk(
  ACTION_ATTRIBUTES_FETCH,
  async (params: IGetAttributesFilterParams) => {
    const resultRaw = await graphqlService.execute(
      queryAttributesAllCard(params as IGetAttributesFilterParams)
    )
    return mapQueryAttributesAll(resultRaw)
  }
)

export const ACTION_TOP_ATTRIBUTES_FETCH = 'attributes/list/top'
export const fetchTopAttributes = createAsyncThunk(
  ACTION_TOP_ATTRIBUTES_FETCH,
  async (params: IGetAttributesFilterParams) => {
    const resultRaw = await graphqlService.execute(
      queryAttributesTopWidget(params as IGetAttributesFilterParams)
    )
    const result = mapQueryAttributesTopWidget(resultRaw)
    return {
      list: result.topList,
      datasourceId: (params.datasourceIds && params.datasourceIds[0]) || '',
      isHighSensitive: params['sensitivity'] === SENSITIVE_LABEL.HIGH
    }
  }
)
export const ACTION_DRIVE_TOP_ATTRIBUTES_FETCH = 'drive/attributes/top'
export const fetchDriveTopAttributes = createAsyncThunk(
  ACTION_DRIVE_TOP_ATTRIBUTES_FETCH,
  async (params: IAttributeFilterParams) => {
    const resultRaw = await graphqlService.execute(queryDriveAttributesTopWidget(params))
    const result = mapQueryDriveAttributesTopWidget(resultRaw)
    const filters = params.filter || []
    const dsIds =
      filters.find((filter) => filter.key === GRAPHQL_API_FILTERS.dataSourceIds)?.values || []
    return { list: result.topList, datasourceId: dsIds[0] || '' }
  }
)

export const ACTION_ATTRIBUTES_FETCH_LIST = 'attributes/listAll/list'
export const fetchAttributesListAll = createAsyncThunk(ACTION_ATTRIBUTES_FETCH_LIST, async () => {
  const resultRaw = await graphqlService.execute(queryAttributeslistAll())
  return mapQueryAttributesListAll(resultRaw)
})

export const ACTION_LIST_TABLE_ATTRIBUTES_FETCH = 'list/attributes/table'
export const fetchListAttributesTable = createAsyncThunk(
  ACTION_LIST_TABLE_ATTRIBUTES_FETCH,
  async (params: IGetAttributesTableListParams & IGetAttributesFilterParams, { getState }) => {
    const queryParams = { ...params, ...getGlobalParams(getState() as RootState) }
    if (params.blobId) {
      const resultRaw = await graphqlService.execute(queryListBlobAttributesTable(queryParams))
      return mapQueryDataSourceAttributesTable(resultRaw, params.blobId)
    } else {
      const resultRaw = await graphqlService.execute(queryListAttributesTable(queryParams))
      return mapQueryDataSourceAttributesTable(resultRaw, params.blobId)
    }
  }
)

export type IGetAttributesTableListParams = {
  [PAGE]: number
  [PAGE_SIZE]?: number
  [DATA_SOURCE_TYPE]?: string
  print?: boolean
  sensitivity?: string
  loadMipTags?: boolean
  blobId?: string
}
export const ACTION_ATTRIBUTES_TABLE_FETCH = 'new/attributes/table'
export const fetchAttributesTable = createAsyncThunk(
  ACTION_ATTRIBUTES_TABLE_FETCH,
  async (params: IGetAttributesTableListParams & IGetAttributesFilterParams, { getState }) => {
    const queryParams = { ...params, ...getGlobalParams(getState() as RootState) }
    const resultRaw = await graphqlService.execute(queryAttributesTable(queryParams))
    return mapQueryAttributesListing(resultRaw)
  }
)
export const ACTION_ATTRIBUTES_TABLE_FETCH_SENSITIVITY_SORTED = 'new/attributes/table/sorted'
export const fetchAttributesTableSensitivitySorted = createAsyncThunk(
  ACTION_ATTRIBUTES_TABLE_FETCH_SENSITIVITY_SORTED,
  async (params: IGetAttributesTableListParams & IGetAttributesFilterParams) => {
    const resultRaw = await graphqlService.execute(queryAttributesTableSensitivitySorted(params))
    return mapQueryAttributesListingSensitivitySorted(resultRaw)
  }
)
export const ACTION_ATTRIBUTES_NAME_IDS_FETCH = 'attributes/nameIds'
export const fetchAttributesNameIds = createAsyncThunk(
  ACTION_ATTRIBUTES_NAME_IDS_FETCH,
  async () => {
    const resultRaw = await graphqlService.execute(queryAttributeNamesIds())
    return mapQueryAttributeNameIds(resultRaw)
  }
)

export interface IGetAttributeInstancesCountParams {
  [FILTER_ATTRIBUTE_DATASOURCEIDS_KEY]?: string[]
  [FILTER_SENSITIVITY]?: string
  filters?: FilterParams
  entityId?: string
  alertId?: string
  blobId?: string
  subAlertAssignees?: string[]
}
export const ACTION_ATTRIBUTE_INSTANCES_COUNT_FETCH = 'attributeInstances/total'
export const fetchAttributeInstancesCount = createAsyncThunk(
  ACTION_ATTRIBUTE_INSTANCES_COUNT_FETCH,
  async (params: IGetAttributeInstancesCountParams) => {
    if (params.blobId) {
      const resultRaw = await graphqlService.execute(queryBlobAttributeInstancesCount(params))
      return mapQueryBlobAttributeInstancesCount(resultRaw)
    } else {
      const resultRaw = await graphqlService.execute(queryAttributeInstancesCount(params))
      return mapQueryAttributeInstancesCount(resultRaw)
    }
  }
)

export const ACTION_ATTRIBUTES_NAME_FETCH = 'attributes/names'
export const fetchAttributesNames = createAsyncThunk(ACTION_ATTRIBUTES_NAME_FETCH, async () => {
  const resultRaw = await graphqlService.execute(queryAttributeNames())
  return mapQueryAttributeNames(resultRaw)
})

export interface CreateAttributeSetParams {
  name: string
  description: string
  type: string
  attributes?: string[]
}

export const ACTION_ATTRIBUTES_SET_CREATE = 'attributesSet/create'
export const createAttributeSet = createAsyncThunk(
  ACTION_ATTRIBUTES_SET_CREATE,
  async (params: CreateAttributeSetParams) => {
    await graphqlService.execute(queryMutationCreateAttributeSet(params))
  }
)

export interface UpdateAttributeSetParams {
  clientMutationId: string
  id: string
  name: string
  description: string
  type: string
  attributes: string[]
}

export const ACTION_ATTRIBUTES_SET_UPDATE = 'attributesSet/update'
export const updateAttributeSet = createAsyncThunk(
  ACTION_ATTRIBUTES_SET_UPDATE,
  async (params: UpdateAttributeSetParams) => {
    await graphqlService.execute(queryMutationUpdateAttributeSet(params))
  }
)

export const ACTION_ATTRIBUTES_SET_ATTRIBUTES_FETCH = 'attribute-set/attributes'
export const fetchAttributesSetWithAttributes = createAsyncThunk(
  ACTION_ATTRIBUTES_SET_ATTRIBUTES_FETCH,
  async () => {
    const resultRaw = await graphqlService.execute(queryAttributesSetWithAttributes())
    return mapQueryAttributesSetWithAttributes(resultRaw)
  }
)

export const ACTION_ATTRIBUTES_SET_SUMMARY_ATTRIBUTES_FETCH = 'attribute-set-summary/attributes'
export const fetchAttributesSetSummaryWithAttributes = createAsyncThunk(
  ACTION_ATTRIBUTES_SET_SUMMARY_ATTRIBUTES_FETCH,
  async () => {
    const resultRaw = await graphqlService.execute(queryAttributesSetSummaryWithAttributes())
    return mapQueryAttributesSetSummaryWithAttributes(resultRaw)
  }
)

export interface DeleteAttributesSetParams {
  id: string
  clientMutationId: string
}

export const ACTION_ATTRIBUTES_SET_DELETE = 'attribute-set/delete'
export const deleteAttributesSet = createAsyncThunk(
  ACTION_ATTRIBUTES_SET_DELETE,
  async (params: DeleteAttributesSetParams) => {
    await graphqlService.execute(queryDeleteAttributesSet(params))
    return params
  }
)

export interface AttributeConditionParams {
  [ALERT_ID]: string
}
export const ACTION_ATTRIBUTES_CONDITION = 'attribute-set/attributesCondition'
export const fetchAttributeCondition = createAsyncThunk(
  ACTION_ATTRIBUTES_CONDITION,
  async (params: AttributeConditionParams) => {
    const resultRaw = await graphqlService.execute(queryAttributeCondition(params))
    return mapQueryAttributeCondition(resultRaw)
  }
)

export const ACTION_ATTRIBUTES_CUSTOM_ATTRIBUTES = 'attribute-set/customAttributes'
export const fetchCustomAttributes = createAsyncThunk(
  ACTION_ATTRIBUTES_CUSTOM_ATTRIBUTES,
  async () => {
    const resultRaw = await graphqlService.execute(queryCustomAttributes())
    return mapQueryCustomAttributes(resultRaw)
  }
)

export const ACTION_ATTRIBUTES_CUSTOM_ATTRIBUTE_BY_ID = 'attribute-set/customAttributeById'
export const fetchCustomAttributeById = createAsyncThunk(
  ACTION_ATTRIBUTES_CUSTOM_ATTRIBUTE_BY_ID,
  async (id: string) => {
    const resultRaw = await graphqlService.execute(queryCustomAttributeById(id))
    return mapQueryCustomAttributeById(resultRaw)
  }
)

export const ACTION_ATTRIBUTES_UPDATE_CUSTOM_ATTRIBUTE = 'attribute-set/updateCustomAttribute'
export const updateCustomAttribute = createAsyncThunk(
  ACTION_ATTRIBUTES_UPDATE_CUSTOM_ATTRIBUTE,
  async (params: CustomAttributeData) => {
    await graphqlService.execute(updateCustomAttributeMutation(params))
  }
)

export const ACTION_ATTRIBUTES_ASSIGN_ATTRIBUTE_SETS = 'attribute-set/assignAttributeSets'
export const assignAttributeSets = createAsyncThunk(
  ACTION_ATTRIBUTES_ASSIGN_ATTRIBUTE_SETS,
  async (params: CustomAttribute) => {
    await graphqlService.execute(assignAttributeSetsMutation(params))
  }
)

export const ACTION_ATTRIBUTE_LIST_ON_DEMAND_SCANNING = 'attributeList/onDemandScanning'
export const fetchAttributeForOnDemandScanning = createAsyncThunk(
  ACTION_ATTRIBUTE_LIST_ON_DEMAND_SCANNING,
  async () => {
    const resultRaw = await graphqlService.execute(queryAttributesForOnDemandScanning())
    return mapQueryAttributesForOnDemandScanning(resultRaw)
  }
)

const attributesSlice = createSlice({
  name: 'attributes',
  initialState,
  reducers: {
    resetWidget: (state) => {
      state.widget = initialState.widget
    },
    resetCards: (state) => {
      state.cards = initialState.cards
    },
    resetAttributeSets: (state) => {
      state.attributeSets = initialState.attributeSets
    },
    resetList: (state) => {
      state.list = initialState.list
    },
    resetAttributeTypeSummary: (state) => {
      state.attributeTypeInfo = initialState.attributeTypeInfo
    },
    resetAttributesSetWithAttributes: (state) => {
      state.attributeSetAttributes = initialState.attributeSetAttributes
    },
    setSort: (state, { payload }) => {
      state.list.sort = getSortDirection(state.list.sort, payload.column)
    },
    setSortForAttributesSet: (state, { payload }) => {
      state.attributeSets.sort = getSortDirection(state.attributeSets.sort, payload.column)
    },
    setSortForAttributesAll: (state, { payload }) => {
      state.attributes.sort = getSortDirection(state.attributes.sort, payload.column)
    },
    resetAttributesAll: (state) => {
      const nameIdListBK = state.attributes.nameIds
      state.attributes = { ...initialState.attributes }
      state.attributes.nameIds = nameIdListBK
    },
    resetCreateEditAttributeSetSuccess: (state) => {
      state.attributeSetsCreateEditSuccess = false
    },
    resetAttributesTopList: (state) => {
      state.attributes.topList = initialTopList
    },
    resetAttributesNameIds: (state) => {
      state.attributes.nameIds = initialState.attributes.nameIds
    },
    resetAttributeCondition: (state) => {
      state.attributeCondition = initialState.attributeCondition
    },
    resetAttributeInstancesCount: (state) => {
      state.attributes.instancesCount = initialState.attributes.instancesCount
    },
    resetSelectedCustomAttribute: (state) => {
      state.selectedCustomAttribute = initialState.selectedCustomAttribute
    },
    resetValidationResult: (state) => {
      state.validationResult = initialState.validationResult
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAttributesWidgetData.fulfilled, (state, { payload }) => {
      state.widget = payload
    })
    builder.addCase(fetchAttributesSetCard.fulfilled, (state, { payload }) => {
      state.attributeSets.cards = payload.list
      state.attributeSets.total = payload.total
    })
    builder.addCase(fetchAttributesSetList.fulfilled, (state, { payload }) => {
      state.attributeSets.list = payload.list
      state.attributeSets.total = payload.total
    })
    builder.addCase(fetchAttributeDataSources.fulfilled, (state, { payload }) => {
      state.attributes.dataSources = payload
    })
    builder.addCase(fetchAttributeUserAccess.fulfilled, (state, { payload }) => {
      state.attributes.userAccess = payload.list
      state.attributes.userAccessTotal = payload.total
    })
    builder.addCase(fetchAttributeGroupAccess.fulfilled, (state, { payload }) => {
      state.attributes.groupAccess = payload.list
      state.attributes.groupAccessTotal = payload.total
    })
    builder.addCase(fetchAttributeUserAccessCards.fulfilled, (state, { payload }) => {
      state.attributes.userAccessCards = payload.cards
      state.attributes.userAccessCardsTotal = payload.total
    })
    builder.addCase(fetchAttributesSetCompactList.fulfilled, (state, { payload }) => {
      state.attributeSets.list = payload.list
      state.attributeSets.total = payload.total
    })
    builder.addCase(setAttributesSetActive.fulfilled, (state, { payload }) => {
      const { id, enabled } = payload.list

      state.attributeSets.list = state.attributeSets.list?.map((item) =>
        item.id === id ? { ...item, enabled } : item
      )
      state.attributeSets.cards = state.attributeSets.cards?.map((card) =>
        card.id === id ? { ...card, enabled } : card
      )
    })
    builder.addCase(fetchAttributesListAllCard.fulfilled, (state, { payload }) => {
      state.attributes.cards = payload.list
      state.attributes.total = payload.total
    })
    builder.addCase(fetchTopAttributes.fulfilled, (state, { payload }) => {
      state.attributes.topList.push(payload)
      payload.isHighSensitive &&
        state.attributes.highSensitiveTopList.push({
          list: payload.list,
          datasourceId: payload.datasourceId
        })
    })
    builder.addCase(fetchDriveTopAttributes.fulfilled, (state, { payload }) => {
      state.attributes.topList.push(payload)
    })
    builder.addCase(fetchAwsTopAttributes.fulfilled, (state, { payload }) => {
      state.attributes.topList.push(payload)
    })
    builder.addCase(fetchAttributesListAll.fulfilled, (state, { payload }) => {
      state.attributes.listAll = payload.list
      state.attributes.listAllTotal = payload.total
    })
    builder.addCase(fetchListAttributesTable.fulfilled, (state, { payload }) => {
      state.attributes.list = payload.list
    })
    builder.addCase(fetchAttributesNameIds.fulfilled, (state, { payload }) => {
      state.attributes.nameIds = payload.list
    })
    builder.addCase(fetchAttributesNames.fulfilled, (state, { payload }) => {
      state.attributes.names = payload.list
    })
    builder.addCase(createAttributeSet.fulfilled, (state) => {
      state.attributeSetsCreateEditSuccess = true
    })
    builder.addCase(updateAttributeSet.fulfilled, (state) => {
      state.attributeSetsCreateEditSuccess = true
    })
    builder.addCase(fetchAttributeCards.fulfilled, (state, { payload }) => {
      state.attributes.cards = payload.list
      state.attributes.total = payload.total
    })
    builder.addCase(fetchAttributesListCards.fulfilled, (state, { payload }) => {
      state.cards = payload
    })
    builder.addCase(fetchSlackAttributeSummary.fulfilled, (state, { payload }) => {
      state.slackCards = payload
    })
    builder.addCase(fetchAttributeTypeSummary.fulfilled, (state, { payload }) => {
      state.attributeTypeInfo = payload
    })
    builder.addCase(fetchAttributesTable.fulfilled, (state, { payload }) => {
      state.attributes.list = payload.list
      state.attributes.total = payload.total
    })
    builder.addCase(fetchAttributesTableSensitivitySorted.fulfilled, (state, { payload }) => {
      state.attributes.list = payload.list
      state.attributes.total = payload.total
    })
    builder.addCase(fetchAwsAttributesTable.fulfilled, (state, { payload }) => {
      state.attributes.list = payload.list
      state.attributes.total = payload.total
    })
    builder.addCase(fetchAttributeInstancesCount.fulfilled, (state, { payload }) => {
      state.attributes.instancesCount = payload.count
    })
    builder.addCase(fetchAttributesSetWithAttributes.fulfilled, (state, { payload }) => {
      state.attributeSetAttributes.list = payload.list
      state.attributeSetAttributes.total = payload.total
    })
    builder.addCase(fetchAttributesSetSummaryWithAttributes.fulfilled, (state, { payload }) => {
      state.attributeSetAttributes.list = payload
    })
    builder.addCase(deleteAttributesSet.fulfilled, (state, { payload }) => {
      const { id } = payload

      state.attributeSets.list = state.attributeSets.list?.filter((item) => item.id !== id)
      state.attributeSets.cards = state.attributeSets.cards?.filter((card) => card.id !== id)
    })
    builder.addCase(fetchAttributeCondition.fulfilled, (state, { payload }) => {
      state.attributeCondition = payload
    })
    builder.addCase(fetchCustomAttributes.fulfilled, (state, { payload }) => {
      state.customAttributes = payload
    })
    builder.addCase(fetchCustomAttributeById.fulfilled, (state, { payload }) => {
      state.selectedCustomAttribute = payload
    })
    builder.addCase(validateRegex.fulfilled, (state, { payload }) => {
      state.validationResult = payload
    })
    builder.addCase(fetchAttributeForOnDemandScanning.fulfilled, (state, { payload }) => {
      state.onDemandScanningAttributes.attributeSetList = payload.attributeSets
      state.onDemandScanningAttributes.attributes = payload.attributes
    })
  }
})

export const {
  setSort,
  resetWidget,
  resetCards,
  resetList,
  resetAttributeSets,
  setSortForAttributesSet,
  resetAttributesAll,
  setSortForAttributesAll,
  resetAttributeTypeSummary,
  resetCreateEditAttributeSetSuccess,
  resetAttributesTopList,
  resetAttributesNameIds,
  resetAttributesSetWithAttributes,
  resetAttributeCondition,
  resetAttributeInstancesCount,
  resetSelectedCustomAttribute,
  resetValidationResult
} = attributesSlice.actions

export default attributesSlice.reducer
