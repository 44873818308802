import './server-error.less'
import NoDataFound from '../NoDataFound'
import { useIntl } from 'react-intl'
import React from 'react'
import { Button, TopNav } from '@lightbeamai/design-system'
import { ArrowClockwise } from 'phosphor-react'

const ServerError = () => {
  const intl = useIntl()
  return (
    <div className="server-error">
      <div className="server-error-container">
        <header className="lb-navbar" data-test-id="navbar">
          <TopNav menuConfig={[]} />
        </header>
        <NoDataFound
          title={intl.formatMessage({ id: 'serverError.title' })}
          secondaryText={intl.formatMessage({ id: 'serverError.text' })}
        />
        <Button onClick={() => window.location.reload()} icon={<ArrowClockwise />}>
          {intl.formatMessage({ id: 'serverError.button' })}
        </Button>
      </div>
    </div>
  )
}

export default ServerError
