import {
  fetchDsrRequestAttribute,
  mapFetchDsrRequestAttribute,
  fetchDsrRequestAllAttributes,
  mapFetchDsrRequestAllAttributes
} from './queries'
import graphqlService from '../../../../../../services/graphqlService'
import {
  Attribute,
  DatasourceType,
  DsrTicketStatus
} from '../../../../../../services/graphqlSchemaTypes'
import { DataSourcesListFilters } from '../../../../../dataSources/dataSourcesSlice'
import { DsrAttributeActionTypes } from '../../../../tickets/dsrTicketsSlice'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export type attributeInstanceValidationTypes = {
  VALIDATED: 'VALIDATED'
  NOT_VALIDATED: 'NOT_VALIDATED'
}

export enum RemoveDuplicateColumnValues {
  ATTRIBUTE_ID = 'ATTRIBUTE_ID',
  ATTRIBUTE_VALUE = 'ATTRIBUTE_VALUE',
  DATASOURCE_ID = 'DATASOURCE_ID',
  ATTRIBUTE_ACTION = 'ATTRIBUTE_ACTION'
}

interface DataSource {
  id: string
  name: string
  type: DatasourceType
}

interface DsrTicket {
  id: string
  assigneeEmail: string
  status: DsrTicketStatus
}

export enum BULK_ATTRIBUTE_ACTION_TYPES {
  selectedAttributes = 'BULK_SELECTED_ATTRIBUTES_ACTION',
  allAttributes = 'BULK_ALL_ATTRIBUTES_ACTION'
}

export type DsrRequestAttribute = {
  id: string
  attributeInstanceId: string
  attributeValidatedId: string
  attributeValue: string
  attributeId: string
  attributeTypeName: string
  identifier: string
  remark: string
  reason: string[]
  dataMismatch: boolean
  action: DsrAttributeActionTypes
  assignee: string
  datasource: DataSource[]
  dsrTickets: DsrTicket[]
  includeInReport: boolean
}

export interface DSRDataDiscoveryInstance {
  attributes?: Attribute[]
  datasourceFilterList: DataSourcesListFilters
  list: DsrRequestAttribute[]
  totalCount: number
  attributeIdsForBulkAction?: string[]
}

export const initialState: DSRDataDiscoveryInstance = {
  datasourceFilterList: {
    dataSourceTypes: [],
    statuses: [],
    createdBy: []
  },
  list: [],
  totalCount: 0
}

export const FETCH_DSR_REQUEST_ATTRIBUTE_IDS_FOR_BULK_ACTION = 'fetch/dsr/attribute/ids/bulk/action'
export const fetchDSRAtrributeIdsForBulkAction = createAsyncThunk(
  FETCH_DSR_REQUEST_ATTRIBUTE_IDS_FOR_BULK_ACTION,
  async (params) => {
    const resultRaw = await graphqlService.execute(fetchDsrRequestAllAttributes(params))
    const mappedData = mapFetchDsrRequestAllAttributes(resultRaw)
    return {
      attributeIds: mappedData,
      status: 'SUCCESS'
    }
  }
)

export const ACTION_FETCH_VALIDATED_DETECTED_DATA = 'dsrDatadiscovery/validatedDetectedList'

export const fetchDsrRequestAttributeList = createAsyncThunk(
  ACTION_FETCH_VALIDATED_DETECTED_DATA,
  async (params) => {
    const resultRaw = await graphqlService.execute(fetchDsrRequestAttribute(params))
    return mapFetchDsrRequestAttribute(resultRaw)
  }
)

const dsrDataDiscoveryDetectedValidatedInstanceSlice = createSlice({
  name: 'dsrDataDiscovery',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDsrRequestAttributeList.fulfilled, (state, { payload }) => {
      state.list = payload.data
      state.totalCount = payload.count
    }),
      builder.addCase(fetchDSRAtrributeIdsForBulkAction.fulfilled, (state, { payload }) => {
        state.attributeIdsForBulkAction = payload.attributeIds
      })
  }
})

export default dsrDataDiscoveryDetectedValidatedInstanceSlice.reducer
