import { mapGlobalSearchResults, queryGlobalSearch } from './queries'
import graphqlService from '../../services/graphqlService'
import {
  ApiStages,
  IResponseStatus,
  responseStatusInitialState
} from '../../services/api/apiStages'
import { GlobalSearchResults, GlobalSearchParams } from '../../services/api/apiTypes'
import { API_ERROR_STATUS } from '../../services/api/apiStatuses'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface IGlobalSearch {
  search: string
  isShowResults: boolean
  result: GlobalSearchResults
  fetchSearch: IResponseStatus
}

export const initialState: IGlobalSearch = {
  search: '',
  isShowResults: true,
  result: {
    labels: [],
    results: []
  },
  fetchSearch: responseStatusInitialState
}

interface IFetchResult extends GlobalSearchParams {
  results: GlobalSearchResults
}

export const fetchSearch = createAsyncThunk(
  'globalSearch/fetchResults',
  async (params: GlobalSearchParams): Promise<IFetchResult> => {
    try {
      const raw = await graphqlService.execute(queryGlobalSearch(params))

      return { ...params, results: mapGlobalSearchResults(raw) }
    } catch (e) {
      throw new Error(e as string)
    }
  }
)

const globalSearchSlice = createSlice({
  name: 'globalSearch',
  initialState,
  reducers: {
    setIsShowResults: (state, action) => {
      state.isShowResults = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSearch.pending, (state) => {
      state.fetchSearch.stage = ApiStages.PENDING
    })
    builder.addCase(fetchSearch.fulfilled, (state, action) => {
      state.fetchSearch.stage = ApiStages.FULFILLED
      state.fetchSearch.error = ''

      //Todo: API fix - sends meesage in results instead of blank array
      const searchData = action.payload.results.results
      const mappedResults = (Array.isArray(searchData) ? searchData : []).map((item) => ({
        id: item.id,
        title: item.name[0],
        records: 1,
        entities: 1
      }))

      state.result = { ...action.payload.results, results: mappedResults }
      state.search = action.payload.value
    })
    builder.addCase(fetchSearch.rejected, (state, action) => {
      state.fetchSearch.stage = ApiStages.REJECTED
      state.fetchSearch.error = action.error?.message || API_ERROR_STATUS.UNHANDLED_ERROR
      state.result = initialState.result
      state.search = initialState.search
    })
  }
})

export const { setIsShowResults } = globalSearchSlice.actions

export default globalSearchSlice.reducer
