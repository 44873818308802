import { TopNavMenuItem } from '@lightbeamai/design-system'

// Recursive function to find the selected key based on the current location
export function findSelectedKey(menuItems: TopNavMenuItem[], location: string) {
  // pathname ex: /insights/attributes?%2Fattributes=hasInstances%3AYes%3Bsensitivity%5B%5D%3AHigh
  // to ex: /insights/entities
  // Remove query params from the location
  location = location.replace(/\?.*/g, '')

  for (const item of menuItems) {
    // Level 1 regex find exact match
    const regex = new RegExp(`^${item?.to}`)

    // Check if the item has children then give priority to the children
    if (item.children) {
      const res = findSelectedKey(item.children, location)
      if (res) {
        return res
      }
    }

    // Check if the location matches the regex
    if (regex.test(location)) {
      return item
    }
  }

  // Only check root match if no direct match or child match was found
  for (const item of menuItems) {
    // Level 2 regex find the root path ignoring query params
    const regexRoot = new RegExp(`^${item?.to?.split('/')[1]}`)

    // Get the root location
    const rootLocation = location?.split('/')?.at(1)

    if (rootLocation && regexRoot.test(rootLocation)) {
      return item
    }
  }
}
