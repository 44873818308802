/**Todo fix confusing maps */
import {
  DATASOURCE_STATUS,
  DATA_SOURCE_TYPES,
  DATA_SOURCE_TYPE_API_MAP,
  DS_FLAVOURS,
  DropboxOnboardingMethod
} from '../constants'
import {
  DataSource,
  DataSourceConfiguration,
  GSUITE_TYPE,
  UnstrcuturedDSConfig
} from '../services/api/apiTypes'
import { ScanStatus } from '../interfaces'
import { DatasourceType } from '../services/graphqlSchemaTypes'
import { IDatasourceInfo } from '../features/dataSources/components/UnstructuredForm/dataSourceForm'

export const getDataSourceImage = (
  type: DATA_SOURCE_TYPES | DatasourceType | string,
  basepath: string,
  fallback?: boolean
): string => {
  if (!type || !DATA_SOURCE_TYPE_API_MAP[type] || fallback) {
    return `${basepath}${DATA_SOURCE_TYPES.generic.toLowerCase()}.png`
  }
  const isAuroraDs =
    type === DATA_SOURCE_TYPES.auroraMySql || type === DATA_SOURCE_TYPES.auroraPostgres
  const dsType = isAuroraDs ? DS_FLAVOURS.aurora : type
  return `${basepath}${dsType.toLowerCase()}.png`
}

/*
  since datasource type is same for different flavours i.e datasource type of Cosmos Postgres and Basic Postgres is postgres we are relieing on scan conditions to identify if given datasource is of cosmos flavour. Easier approach is to query the flavour field in configuration, however when new datasource is registered the configuration field is not available immediately due to which ds listing page breaks. To avoid this we are using scan conditions
  For PG of cosmos flavour the database name will be unique for all conditions and each condition will have a value non-null schema name
*/

export const getActualDataSourceTypeFromConfig = (
  dataSourceConfig: DataSourceConfiguration
): DATA_SOURCE_TYPES | string => {
  const { datasourceType, gsuiteConfiguration } = dataSourceConfig
  const isPersonalGDrive =
    gsuiteConfiguration &&
    datasourceType === DATA_SOURCE_TYPES.gdrive &&
    gsuiteConfiguration.accountType === GSUITE_TYPE.personal
  const finalType = isPersonalGDrive ? DATA_SOURCE_TYPES.gdrivePersonal : datasourceType

  return finalType
}

export const mapDataSourceGraphQlStatus = {
  Registering: ScanStatus.registering,
  SCANNING: ScanStatus.scanning,
  SCANNED: ScanStatus.completed,
  Scanned: ScanStatus.completed,
  ...ScanStatus
}

export const DATA_SOURCE_UNSTRUCTURED_TYPES = {
  [DATA_SOURCE_TYPES.gdrive]: true,
  [DATA_SOURCE_TYPES.gmail]: true,
  [DATA_SOURCE_TYPES.slack]: true,
  [DATA_SOURCE_TYPES.teams]: true,
  [DATA_SOURCE_TYPES.elasticSearch]: true,
  [DATA_SOURCE_TYPES.oneDrive]: true,
  [DATA_SOURCE_TYPES.sharePoint]: true,
  [DATA_SOURCE_TYPES.box]: true,
  [DATA_SOURCE_TYPES.aws]: true,
  [DATA_SOURCE_TYPES.googleCloudStorage]: true,
  [DATA_SOURCE_TYPES.azureBlob]: true,
  [DATA_SOURCE_TYPES.outLook]: true,
  [DATA_SOURCE_TYPES.generic]: true,
  [DATA_SOURCE_TYPES.jira]: true,
  [DATA_SOURCE_TYPES.smb]: true,
  [DATA_SOURCE_TYPES.exchange]: true,
  [DATA_SOURCE_TYPES.dropbox]: true
}

export const getStreamNameForApi = (streamName: string) => {
  if (streamName === 'consents') return 'subscription_changes'
  return streamName
}
export const getStreamEntityCreationEnabled = (streamName: string) => {
  if (streamName === 'subscription_changes') return false
  return true
}

export const isDsReady = (status: DataSource['status']) => {
  return status && status !== DATASOURCE_STATUS.registering && status !== DATASOURCE_STATUS.deleting
}

export const isDsDeleting = (status: DataSource['status']) => status === DATASOURCE_STATUS.deleting

export const mapConfigurationByType = (
  values: IDatasourceInfo,
  datasourceType: DATA_SOURCE_TYPES
): IDatasourceInfo => {
  const mappedValues = ({ ...values } as unknown) as UnstrcuturedDSConfig
  const isDropbox = datasourceType === DATA_SOURCE_TYPES.dropbox
  const isDocAnalyticsEnabled = isDocAnalyticsEnabledOnSelectedDs(datasourceType)
  if (isDropbox) {
    const { authType } = mappedValues
    if (authType === DropboxOnboardingMethod.oauth) {
      delete mappedValues.appKey
      delete mappedValues.appSecret
      delete mappedValues.refreshToken
      delete mappedValues.teamAdminEmail
    }
  }
  if (isDocAnalyticsEnabled) {
    delete mappedValues.docAnalyticsConfiguration
  }
  return (mappedValues as unknown) as IDatasourceInfo
}

const DATA_SOURCES_WITH_DOC_ANALYTICS = [
  DATA_SOURCE_TYPES.sharePoint,
  DATA_SOURCE_TYPES.smb,
  DATA_SOURCE_TYPES.oneDrive
]

export const isDocAnalyticsEnabledOnSelectedDs = (dataSourceType) => {
  return DATA_SOURCES_WITH_DOC_ANALYTICS.includes(dataSourceType)
}

const DATA_SOURCES_WITH_INBOX_SCANNING = [DATA_SOURCE_TYPES.outLook]

export const isInboxScanningEnabledOnSelectedDs = (dataSourceType) => {
  return DATA_SOURCES_WITH_INBOX_SCANNING.includes(dataSourceType)
}

export const isDatasourceFileCountersEnabled = () => {
  return window.__featureFlags.dashboardCountersForFileDataSourcesEnabled
}
