import { FormattedMessage } from 'react-intl'
import React from 'react'
import { Typography } from '@lightbeamai/design-system'
import './no-data-found.less'

interface IProps {
  title?: string
  secondaryText?: string
  className?: string
  fullWidth?: boolean
}

const NoDataFound = (props: IProps): React.ReactElement => {
  const { secondaryText, title, className, fullWidth } = props
  return (
    <div className={`no-result-container ${fullWidth ? 'full-width' : ''} ${className}`}>
      <img src="/assets/images/no-results-found.png" alt="No Results Found..." />
      <div className={`no-result-content-wrapper`}>
        <Typography color="primary" variant="h4" type="strong" className="xs-mt-0">
          {title ? title : <FormattedMessage id="text.noResults" />}
        </Typography>
        {secondaryText && (
          <Typography color="secondary" variant="base">
            {secondaryText}
          </Typography>
        )}
      </div>
    </div>
  )
}

export default NoDataFound
