import {
  mapQueryAlertDetails,
  mapQueryDatabaseDetails,
  mapQueryDataSourceDetails,
  mapQueryDataSourceEntitiesCount,
  mapQueryDataSourceRiskyFilesCount,
  mapQueryEntityWidgets,
  mapQueryTableDetails,
  queryAlertDetails,
  queryDatabaseDetails,
  queryDataSourceDetails,
  queryDataSourceEntitiesCount,
  queryDataSourceRiskyFilesCount,
  queryEntityWidgets,
  queryTableDetails,
  queryGDriveDriveSummaryData,
  mapQueryGDriveDriveSummaryData,
  queryProjectsSummaryWidgetData,
  mapQueryProjectsSummaryWidgetData,
  queryGenericDataSourceSummaryById,
  mapQueryGenericDataSourceSummaryById,
  queryGenericProjectSummaryData,
  mapQueryGenericProjectSummaryData,
  queryJiraProjectsCount,
  mapQueryJiraProjectsCount,
  mapQueryJiraTicketsWithPIICount,
  queryJiraTicketsWithPIICount,
  queryJiraSummaryById,
  mapQueryJiraSummaryById,
  queryAWSS3SummaryCounts,
  mapQueryAWSS3BucketsCount,
  queryAwsSummaryWidget,
  mapQueryAwsSummaryWidget,
  querySlackScannedChannelsCount,
  mapQuerySlackScannedChannelsCount,
  queryEntityAttributes,
  mapQueryEntityAttributes,
  mapQueryEntityTypesLables,
  queryEntityTypesLables,
  queryTeamsCount,
  mapQueryTeamsCount,
  getEntitySummary,
  mapEntitySummary
} from './queries'
import service from '../../services/api/apiService'
import {
  GDriveSummary,
  GDriveSummaryQueryParams,
  GMailSummaryQueryParams,
  GMailSummary,
  OutlookSummary,
  OutlookSummaryQueryParams,
  OneDriveSummary,
  OneDriveSummaryQueryParams,
  SharePointSummaryQueryParams,
  AWSS3Summary,
  AWSS3SummaryQueryParams,
  SharePointSummary,
  UserEntity,
  GenericSummary,
  JiraSummary,
  BucketSummaryQueryParams,
  BoxSummary,
  MessagesSummary
} from '../../services/api/apiTypes'
import {
  ALERT_ID,
  ALERT_STATUS,
  DATABASE_ID,
  DATA_SOURCE_ID,
  DATA_SOURCE_TYPES,
  ENTITY_ID,
  POLICY_TYPES,
  SEVERITY_LEVEL,
  TABLE_ID
} from '../../constants'
import { FilterParams } from '../../interfaces'
import graphqlService from '../../services/graphqlService'
import { TableChangeTypes } from '../tables/tablesSlice'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export type TableSummary = {
  tableId: string
  tableName: string
  isReviewed: boolean
  schemaId: string
  owner?: string
  size?: number
  columnsCount?: number
  columnsSensitiveCount?: number
  rowsCount?: number
  changeType?: TableChangeTypes
  databaseId: string
}

export type DatabaseSummary = {
  databaseId: string
  databaseName: string
  owner?: string
  size?: number
  schemasCount?: number
  tablesCount?: number
  columnsCount?: number
  columnsSensitiveCount?: number
  notificationEnabled?: boolean
  notificationOwners?: string[]
  notificationAcknowledged?: boolean
}

export type DataSourceSummary = {
  dataSourceId: string
  dataSourceName: string
  owner?: string
  size?: number
  location?: string
  lastUpdated?: string
}

export type EntityWidgets = {
  attributesCount: number
  dataSourcesCount: number
  violationsCount: number
  objectsCount: number
}
export interface GDriveDriveSummary extends GDriveSummary {
  driveOwner: string
  groupsCount: number
  membersCount: number
}

export interface GenericProjectSummary extends GenericSummary {
  projectName?: string
  projectOwner?: string
  objectsCount?: number
  isDefaultProject?: boolean
}

export interface ISlackSummary extends MessagesSummary {
  scannedChannelsCount?: number
}

export type SummaryState = {
  gDrive?: GDriveSummary
  gDriveDrive?: GDriveDriveSummary
  slack: MessagesSummary
  teams: MessagesSummary
  gMail?: GMailSummary
  outLook?: OutlookSummary
  oneDrive?: OneDriveSummary
  generic?: GenericSummary
  genericProject?: GenericProjectSummary
  jira: JiraSummary
  table?: TableSummary
  database?: DatabaseSummary
  dataSource?: DataSourceSummary
  awsS3: AWSS3Summary
  sharePoint?: SharePointSummary
  box?: BoxSummary
  entity?: UserEntity
  entityWidgets?: EntityWidgets
  alert?: AlertSummary
  dataSourceEntitiesCount?: number
  dataSourceRiskyFilesCount?: number
  entityAttributes?: EntityAttribute[]
  entityLabels?: string[]
  entityTypes?: string[]
  hasHoldExpired?: boolean
  isOnLegalHold?: boolean
  lastModifiedTime?: string
}

export const initialState: SummaryState = {
  jira: {},
  awsS3: {},
  slack: {},
  teams: {}
}

export const fetchGDriveSummaryData = createAsyncThunk(
  'summary/gdrive',
  async (filters: GDriveSummaryQueryParams) => await service.getGDriveSummaryWidgetData(filters)
)

export const fetchOneDriveSummaryData = createAsyncThunk(
  'summary/onedrive',
  async (filters: OneDriveSummaryQueryParams) => await service.getOneDriveSummaryWidgetData(filters)
)

export const fetchSharePointSummaryData = createAsyncThunk(
  'summary/sharepoint',
  async (filters: SharePointSummaryQueryParams) =>
    await service.getSharePointSummaryWidgetData(filters)
)
export const fetchAWSS3SummaryData = createAsyncThunk(
  'summary/awss3',
  async (filters: AWSS3SummaryQueryParams) => await service.getAWSS3SummaryWidgetData(filters)
)

export const ACTION_FETCH_BUCKETS_SUMMARY = 'summary/bucket'
export const fetchBucketsSummaryData = createAsyncThunk(
  ACTION_FETCH_BUCKETS_SUMMARY,
  async (params: BucketSummaryQueryParams) => {
    const resultRaw = await graphqlService.execute(queryAwsSummaryWidget(params))

    return mapQueryAwsSummaryWidget(resultRaw)
  }
)

// slack
export const fetchSlackSummaryData = createAsyncThunk(
  'summary/slack',
  async (filters: GDriveSummaryQueryParams) => await service.getMessagesWidgetData(filters)
)
export const fetchSlackScannedChannelsCount = createAsyncThunk(
  'slack/channels/scanned/count',
  async (datasourceId: string) => {
    const resultRaw = await graphqlService.execute(querySlackScannedChannelsCount(datasourceId))
    return mapQuerySlackScannedChannelsCount(resultRaw)
  }
)

// teams
export const ACTION_TEAMS_SUMMARY = 'summary/teams'
export const fetchTeamsSummaryData = createAsyncThunk(
  ACTION_TEAMS_SUMMARY,
  async (params: GDriveSummaryQueryParams) => {
    const summary = await service.getMessagesWidgetData(params)
    const resultRaw = await graphqlService.execute(queryTeamsCount(params.datasourceId))
    const teamsCount = mapQueryTeamsCount(resultRaw)
    return { ...summary, teamsCount }
  }
)

export const fetchGMailSummaryData = createAsyncThunk(
  'summary/gmail',
  async (filters: GMailSummaryQueryParams) => await service.getGMailSummaryWidgetData(filters)
)

export const fetchOutlookSummaryData = createAsyncThunk(
  'summary/outlook',
  async (filters: OutlookSummaryQueryParams) => await service.getOutlookSummaryWidgetData(filters)
)

export interface EntitySummaryParams {
  [ENTITY_ID]: string
  [DATA_SOURCE_ID]?: string
}
export const ACTION_ENTITY_SUMMARY_FETCH = 'summary/entity'
export const fetchEntitySummary = createAsyncThunk(
  ACTION_ENTITY_SUMMARY_FETCH,
  async (params: EntitySummaryParams) => {
    const resultRaw = await graphqlService.execute(getEntitySummary(params))

    return mapEntitySummary(resultRaw)
  }
)

export interface EntityAttribute {
  internalName: string
  value: string
}
export interface EntityLabels {
  labels: string[]
  types: string[]
  hasHoldExpired?: boolean
  isOnLegalHold?: boolean
  lastModifiedTime?: string
}
export const ACTION_ENTITY_ATTRIBUTES = 'summary/entityAttributes'
export const fetchEntityAttributes = createAsyncThunk(
  ACTION_ENTITY_ATTRIBUTES,
  async (entityId: string) => {
    const resultRaw = await graphqlService.execute(queryEntityAttributes(entityId))
    return mapQueryEntityAttributes(resultRaw)
  }
)
export const ACTION_ENTITY_TYPES = 'summary/entityTypes'
export const fetchEntityTypes = createAsyncThunk(ACTION_ENTITY_TYPES, async (entityId: string) => {
  const resultRaw = await graphqlService.execute(queryEntityTypesLables(entityId))
  return mapQueryEntityTypesLables(resultRaw)
})
export const ACTION_ENTITY_WIDGETS_FETCH = 'summary/entityWidgets'
export const fetchEntityWidgets = createAsyncThunk(
  ACTION_ENTITY_WIDGETS_FETCH,
  async (params: EntitySummaryParams) => {
    const resultRaw = await graphqlService.execute(queryEntityWidgets(params))
    return mapQueryEntityWidgets(resultRaw)
  }
)

export interface TableSummaryParams {
  [TABLE_ID]: string
}

export const fetchTableSummary = createAsyncThunk(
  'summary/table',
  async (params: TableSummaryParams) => {
    const resultRaw = await graphqlService.execute(queryTableDetails(params))
    return mapQueryTableDetails(resultRaw)
  }
)
export interface DatabaseSummaryParams {
  [DATABASE_ID]: string
}
export const fetchDatabaseSummary = createAsyncThunk(
  'summary/database',
  async (params: DatabaseSummaryParams) => {
    const resultRaw = await graphqlService.execute(queryDatabaseDetails(params))
    return mapQueryDatabaseDetails(resultRaw)
  }
)

export interface DataSourceSummaryParams {
  [DATA_SOURCE_ID]: string
}
export const fetchDataSourceSummary = createAsyncThunk(
  'summary/dataSource',
  async (params: DataSourceSummaryParams) => {
    const resultRaw = await graphqlService.execute(queryDataSourceDetails(params))
    return mapQueryDataSourceDetails(resultRaw)
  }
)

export interface GDriveDriveSummaryQueryParams {
  datasourceId: string
  filters: FilterParams
}
export const fetchGDriveDriveSummaryData = createAsyncThunk(
  'summary/gdrive/drive',
  async (params: GDriveDriveSummaryQueryParams) => {
    const resultRaw = await graphqlService.execute(queryGDriveDriveSummaryData(params))
    return mapQueryGDriveDriveSummaryData(resultRaw)
  }
)
export const GENERIC_SUMMARY_COUNT = 'datasource/generic/summary/counts'
export const fetchProjectsSummaryWidgetData = createAsyncThunk(
  GENERIC_SUMMARY_COUNT,
  async (datasourceId: string) => {
    const resultRaw = await graphqlService.execute(queryProjectsSummaryWidgetData(datasourceId))
    return mapQueryProjectsSummaryWidgetData(resultRaw)
  }
)

export interface FetchJiraPIITicketsCountParams {
  datasourceId: string
  filters?: FilterParams
}
export const ACTION_JIRA_PII_TICKETS_COUNT_FETCH = 'datasource/jira/summary/counts'
export const fetchJiraPIITicketsCount = createAsyncThunk(
  ACTION_JIRA_PII_TICKETS_COUNT_FETCH,
  async (params: FetchJiraPIITicketsCountParams) => {
    const resultRaw = await graphqlService.execute(queryJiraTicketsWithPIICount(params))
    return mapQueryJiraTicketsWithPIICount(resultRaw)
  }
)

export const fetchJiraProjectsCount = createAsyncThunk(
  'datasource/jira/projects/counts',
  async (datasourceId: string) => {
    const resultRaw = await graphqlService.execute(queryJiraProjectsCount(datasourceId))
    return mapQueryJiraProjectsCount(resultRaw)
  }
)

export const fetchGenericDataSourceSummaryById = createAsyncThunk(
  'datasource/generic/summary/widget',
  async (id: string) => {
    const results = await graphqlService.execute(queryGenericDataSourceSummaryById(id))
    return mapQueryGenericDataSourceSummaryById(results)
  }
)

export interface GenericProjectSummaryQueryParams {
  projectId: string
}
export const fetchGenericProjectSummaryData = createAsyncThunk(
  'datasource/generic/project/summary',
  async (params: GenericProjectSummaryQueryParams) => {
    const results = await graphqlService.execute(queryGenericProjectSummaryData(params))
    return mapQueryGenericProjectSummaryData(results)
  }
)

export const fetchJiraSummaryById = createAsyncThunk(
  'datasource/jira/summary/widget',
  async (id: string) => {
    const results = await graphqlService.execute(queryJiraSummaryById(id))
    return mapQueryJiraSummaryById(results)
  }
)

export interface DriveSummaryCountParams {
  [DATA_SOURCE_ID]?: string
  filters?: FilterParams
}
export const ACTION_DATASOURCE_ENTITIES_COUNT_FETCH = 'summary/dataSource/entities-count'
export const fetchDataSourceEntitiesCount = createAsyncThunk(
  ACTION_DATASOURCE_ENTITIES_COUNT_FETCH,
  async (params: DriveSummaryCountParams) => {
    const resultRaw = await graphqlService.execute(queryDataSourceEntitiesCount(params))
    return mapQueryDataSourceEntitiesCount(resultRaw)
  }
)

export const ACTION_DATASOURCE_FILES_RISKY_COUNT_FETCH = 'summary/dataSource/files-risky-count'
export const fetchDataSourceRiskyFilesCount = createAsyncThunk(
  ACTION_DATASOURCE_FILES_RISKY_COUNT_FETCH,
  async (params: DriveSummaryCountParams) => {
    const resultRaw = await graphqlService.execute(queryDataSourceRiskyFilesCount(params))
    return mapQueryDataSourceRiskyFilesCount(resultRaw)
  }
)

export interface AWSS3SummaryCountParams {
  datasourceId: string
}

export const fetchAWSS3SummaryCounts = createAsyncThunk(
  'datasouce/awss3/buckets',
  async (params: AWSS3SummaryCountParams) => {
    const resultRaw = await graphqlService.execute(queryAWSS3SummaryCounts(params))
    return mapQueryAWSS3BucketsCount(resultRaw)
  }
)

export type AlertSummaryParams = {
  [ALERT_ID]: string
}

export type AlertSummary = {
  id: string
  name: string
  status: ALERT_STATUS
  severity: SEVERITY_LEVEL
  assignees: string[]
  assigneesCount?: number
  dataSourceId: string
  dataSourceName: string
  dataSourceType: DATA_SOURCE_TYPES
  description: string
  policyName: POLICY_TYPES
}

export const ACTION_ALERT_DETAILS = 'summary/alert'
export const fetchAlertSummary = createAsyncThunk(
  ACTION_ALERT_DETAILS,
  async (params: AlertSummaryParams) => {
    const result = await graphqlService.execute(queryAlertDetails(params))
    return mapQueryAlertDetails(result)
  }
)

const summarySlice = createSlice({
  name: 'summary',
  initialState,
  reducers: {
    reset: () => initialState,
    resetTableSummary: (state) => {
      state.table = initialState.table
    },
    resetDatabaseSummary: (state) => {
      state.database = initialState.database
    },
    resetDataSourceSummary: (state) => {
      state.dataSource = initialState.dataSource
    },
    resetEntitySummary: (state) => {
      state.entity = initialState.entity
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchGDriveSummaryData.fulfilled, (state, action) => {
      state.gDrive = action.payload
    })
    builder.addCase(fetchGDriveDriveSummaryData.fulfilled, (state, action) => {
      state.gDriveDrive = action.payload.data
    })
    builder.addCase(fetchOneDriveSummaryData.fulfilled, (state, action) => {
      state.oneDrive = action.payload
    })
    builder.addCase(fetchSharePointSummaryData.fulfilled, (state, action) => {
      state.sharePoint = action.payload
    })
    builder.addCase(fetchAWSS3SummaryData.fulfilled, (state, action) => {
      state.awsS3 = { ...state.awsS3, ...action.payload }
    })
    builder.addCase(fetchSlackSummaryData.fulfilled, (state, action) => {
      state.slack = { ...state.slack, ...action.payload }
    })
    builder.addCase(fetchSlackScannedChannelsCount.fulfilled, (state, { payload }) => {
      state.slack.scannedChannelsCount = payload
    })
    builder.addCase(fetchTeamsSummaryData.fulfilled, (state, action) => {
      state.teams = action.payload
    })
    builder.addCase(fetchGMailSummaryData.fulfilled, (state, action) => {
      state.gMail = action.payload
    })
    builder.addCase(fetchOutlookSummaryData.fulfilled, (state, action) => {
      state.outLook = action.payload
    })
    builder.addCase(fetchDataSourceSummary.fulfilled, (state, action) => {
      state.dataSource = action.payload
    })
    builder.addCase(fetchDatabaseSummary.fulfilled, (state, action) => {
      state.database = action.payload
    })
    builder.addCase(fetchTableSummary.fulfilled, (state, action) => {
      state.table = action.payload
    })
    builder.addCase(fetchEntitySummary.fulfilled, (state, action) => {
      state.entity = action.payload
    })
    builder.addCase(fetchEntityWidgets.fulfilled, (state, action) => {
      state.entityWidgets = action.payload
    })
    builder.addCase(fetchAlertSummary.fulfilled, (state, action) => {
      state.alert = action.payload
    })
    builder.addCase(fetchDataSourceEntitiesCount.fulfilled, (state, action) => {
      state.dataSourceEntitiesCount = action.payload.total
    })
    builder.addCase(fetchDataSourceRiskyFilesCount.fulfilled, (state, action) => {
      state.dataSourceRiskyFilesCount = action.payload.total
    })
    builder.addCase(fetchProjectsSummaryWidgetData.fulfilled, (state, action) => {
      state.generic = { ...state.generic, ...action.payload }
    })
    builder.addCase(fetchJiraPIITicketsCount.fulfilled, (state, action) => {
      state.jira.ticketsWithPIICount = action.payload.ticketsCount
    })
    builder.addCase(fetchJiraProjectsCount.fulfilled, (state, action) => {
      state.jira.projectsCount = action.payload.projectsCount
    })
    builder.addCase(fetchJiraSummaryById.fulfilled, (state, action) => {
      state.jira.ticketsCount = action.payload.ticketsCount
      state.jira.location = action.payload.location
    })
    builder.addCase(fetchGenericDataSourceSummaryById.fulfilled, (state, action) => {
      state.generic = { ...state.generic, ...action.payload }
    })
    builder.addCase(fetchGenericProjectSummaryData.fulfilled, (state, action) => {
      state.genericProject = action.payload.data
    })
    builder.addCase(fetchAWSS3SummaryCounts.fulfilled, (state, action) => {
      state.awsS3.bucketsCount = action.payload.bucketsCount
      state.awsS3.objectsCount = action.payload.objectsCount
      state.awsS3.policiesCount = action.payload.policiesCount
    })
    builder.addCase(fetchBucketsSummaryData.fulfilled, (state, action) => {
      state.awsS3.bucketSize = action.payload.bucketCount
      state.awsS3.bucketName = action.payload.bucketName
      state.awsS3.bucketOwner = action.payload.bucketOwner
      state.awsS3.bucketAccess = action.payload.bucketAccess
      state.awsS3.displayName = action.payload.displayName
    })

    builder.addCase(fetchEntityAttributes.fulfilled, (state, { payload }) => {
      state.entityAttributes = payload
    })
    builder.addCase(fetchEntityTypes.fulfilled, (state, { payload }) => {
      state.entityTypes = payload.types
      state.entityLabels = payload.labels
      state.hasHoldExpired = payload.hasHoldExpired
      state.isOnLegalHold = payload.isOnLegalHold
      state.lastModifiedTime = payload.lastModifiedTime
    })
  }
})

export const {
  reset,
  resetTableSummary,
  resetDatabaseSummary,
  resetDataSourceSummary,
  resetEntitySummary
} = summarySlice.actions

export default summarySlice.reducer
