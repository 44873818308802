import '../../../common.less'
import './selectFileToCreateFilter.less'
import {
  FILTER_FILE_IMPORT_OPTIONS,
  URL_FILTER,
  URL_FILTERS,
  URL_POLICIES
} from '../../../../constants'
import { RootState } from '../../../../rootReducer'
import { uploadFileForFilter, resetProfileMapId } from '../../policiesSlice'
import LoaderView from '../../../../components/Loader/loader'
import HeaderWithActions from '../HeaderWithActions'
import { getShowLoader } from '../../../../reducers/requestReducer'
import { DownloadSimple as IconDownloadSimple, X as IconX } from 'phosphor-react'
import { Button, Modal, Dropdown, Input, InputProps, DropdownProps } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { useIntl, FormattedMessage } from 'react-intl'
import cx from 'classnames'
import React, { useState, useRef, ChangeEvent, SyntheticEvent, useEffect } from 'react'
import { Typography } from '@lightbeamai/design-system'

interface IProps {
  uploadFileForFilter: (file: File) => void
  resetProfileMapId: () => void
  showLoader: boolean
  profileMapId?: string
}

const SelectFileToCreateFilter = ({
  uploadFileForFilter,
  resetProfileMapId,
  showLoader,
  profileMapId
}: IProps): React.ReactElement => {
  const intl = useIntl()
  const history = useHistory()
  const [showFileImportModal, setShowFileImportModal] = useState(false)
  const [importType, setImportType] = useState<FILTER_FILE_IMPORT_OPTIONS>(
    FILTER_FILE_IMPORT_OPTIONS.file
  )
  const [selectedFilePath, setSelectedFilePath] = useState('')
  const [fileSelected, setFileSelected] = useState<File | null>(null)
  const [url, setURL] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const browserAlertsList = () => {
    const urlFiltersDashboard = URL_POLICIES + URL_FILTERS
    history.push(urlFiltersDashboard)
  }

  useEffect(() => {
    resetProfileMapId()
  }, [])

  useEffect(() => {
    selectedFilePath && profileMapId && history.push(URL_FILTER)
  }, [profileMapId, selectedFilePath, history])

  const dropdownOptions = [
    {
      key: FILTER_FILE_IMPORT_OPTIONS.file,
      text: intl.formatMessage({ id: 'filter.import.options.file' }),
      value: FILTER_FILE_IMPORT_OPTIONS.file
    },
    {
      key: FILTER_FILE_IMPORT_OPTIONS.webURL,
      text: intl.formatMessage({ id: 'filter.import.options.webURL' }),
      value: FILTER_FILE_IMPORT_OPTIONS.webURL,
      disabled: true
    }
  ]

  const handleBrowseFile = () => {
    inputRef?.current && inputRef.current.click()
  }

  const handleUploadFile = () => {
    fileSelected && uploadFileForFilter(fileSelected)
  }

  const handleChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files

    if (files?.length) {
      const fileSelected = files[0]
      setFileSelected(fileSelected)
      setSelectedFilePath(fileSelected.name)
    }
  }

  const isFileType = importType === FILTER_FILE_IMPORT_OPTIONS.file
  const isURLType = importType === FILTER_FILE_IMPORT_OPTIONS.webURL

  const handleChangeURL = (_: SyntheticEvent, { value }: InputProps) => {
    value && setURL(value)
  }

  const handleTypeChange = (_: SyntheticEvent, { value }: DropdownProps) => {
    value && setImportType(value as FILTER_FILE_IMPORT_OPTIONS)
  }

  const transFilePlaceholder = intl.formatMessage({ id: 'filter.import.options.file.placeholder' })
  const transWebURLPlaceholder = intl.formatMessage({
    id: 'filter.import.options.webURL.placeholder'
  })
  const transHeaderTitle = intl.formatMessage({
    id: 'filter.import.screen.header.title'
  })
  const transCloseScreen = intl.formatMessage({
    id: 'filter.screen.action.close'
  })

  return (
    <div className="select-filter-option" data-test-id="policies-select-filter-option">
      <HeaderWithActions
        title={transHeaderTitle}
        closeText={transCloseScreen}
        onClose={browserAlertsList}
        className="xs-px-32 xs-py-20"
      />
      <div className="select-filter-content">
        <Typography as="p" variant="h3" type="normal" color="primary" className="xs-mb-16">
          <FormattedMessage id="filter.import.screen.title" />
        </Typography>
        <Typography as="p" variant="base" color="secondary" className="xs-m-0 xs-px-16">
          <FormattedMessage id="filter.import.screen.text.secondary" />
        </Typography>
        <div className="filter-options">
          <div
            onClick={() => setShowFileImportModal(true)}
            className={cx('box-import-file', { active: showFileImportModal })}
          >
            <p className="xs-mb-28">
              <IconDownloadSimple size={48} color="#959DA5" weight="light" />
            </p>
            <Typography as="p" color="primary" variant="basePlus2" className="xs-m-0">
              <FormattedMessage id="filter.import.popup.title" />
            </Typography>
          </div>
        </div>
      </div>
      <Modal className="import-file-entity-filter" open={showFileImportModal} size="small">
        <LoaderView showLoader={showLoader} />
        <Modal.Content>
          <header className="xs-mb-32">
            <span>
              <FormattedMessage id="filter.import.popup.title" />
            </span>
            <IconX size={22} onClick={() => setShowFileImportModal(false)} color="#444D56" />
          </header>
          <div>
            <input
              ref={inputRef}
              type="file"
              accept=".csv,text/csv"
              hidden
              onChange={handleChangeFile}
            />
            <div className="import-control">
              <div className="select-option">
                <Dropdown
                  fluid
                  selection
                  options={dropdownOptions}
                  defaultValue={importType}
                  onChange={handleTypeChange}
                />
              </div>
              <div className="file-path xs-py-8 xs-px-12">
                {isFileType && (
                  <label onClick={handleBrowseFile}>
                    {selectedFilePath || transFilePlaceholder}
                  </label>
                )}
                {isURLType && (
                  <Input
                    type="text"
                    placeholder={transWebURLPlaceholder}
                    onChange={handleChangeURL}
                    value={url}
                  />
                )}
              </div>
              {isFileType && (
                <button className="xs-p-0" onClick={handleBrowseFile}>
                  <FormattedMessage id="filter.import.button.browse" />
                </button>
              )}
            </div>
          </div>
          <p className="help-text xs-mt-8 xs-mb-32">
            <em>
              <FormattedMessage id="filter.import.help.text" />
            </em>
          </p>
          <div className="file-import">
            <Button
              {...(selectedFilePath ? { primary: true } : { disabled: true })}
              onClick={handleUploadFile}
            >
              <FormattedMessage id="filter.import.button.import" />
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    showLoader: getShowLoader(),
    profileMapId: state.policies.profileMapId
  }
}

const mapDispatchToProps = {
  uploadFileForFilter,
  resetProfileMapId
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectFileToCreateFilter)
