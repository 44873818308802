import { fetchSearch } from './globalSearchSlice'
import { getIsLoading } from './globalSearchSelectors'
import Search from '../../components/Search/search'
import { RootState } from '../../rootReducer'
import { GlobalSearchResults, GlobalSearchParams } from '../../services/api/apiTypes'
import React, { useCallback } from 'react'
import { connect } from 'react-redux'

interface IProps {
  isLoading: boolean
  isShowResults: boolean
  results: GlobalSearchResults
  fetchSearch: (params: GlobalSearchParams) => void
}

const GlobalSearch = (props: IProps): React.ReactElement => {
  const { fetchSearch, results, isLoading, isShowResults } = props

  const handleChange = useCallback(
    (value: string) => {
      fetchSearch({ value })
    },
    [fetchSearch]
  )

  return (
    <Search
      className="global-search"
      onChange={handleChange}
      isShowResults={isShowResults}
      results={results}
      isLoading={isLoading}
      type="collapsible"
    />
  )
}

const mapStateToProps = (state: RootState) => ({
  results: state.globalSearch.result,
  isShowResults: state.globalSearch.isShowResults,
  isLoading: getIsLoading(state)
})

const mapDispatchToProps = { fetchSearch }

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearch)
