import './dataSourceSettings.less'
import '../../features/dataSources/dataSources.less'
import '../../components/NavTabs/navTabs.less'
import ControlPane from './components/controlPane'
import StepsTabs, { StepTab } from './components/stepsTabs'
import StepsBar from '../../components/Stepper/stepper'
import ModalDeleteDataSource from '../../features/dataSources/components/modalDeleteDataSource/modalDelete'
import useUrlContext from '../../hooks/useUrlContext'
import {
  ACTION_DATA_SOURCE_PARTIAL_UPDATE,
  ACTION_DATA_SOURCE_UPDATE,
  deleteDataSourceById
} from '../../features/dataSources/dataSourcesSlice'
import { addToUrlQuery, changeUrl } from '../../utils/urlUtil'
import {
  ACTION_TYPE,
  DATA_SOURCE_TYPE,
  DATA_SOURCE_TYPES,
  FEATURE_FLAGS,
  STRUCTURED_DATASOURCES,
  URL_DATA_SOURCES
} from '../../constants'
import { getActionState } from '../../reducers/requestReducer'
import { STATES } from '../../interfaces'
import { RootState } from '../../rootReducer'
import { FeatureFlags } from '../../configs/featureFlagSlice'
import { isDocAnalyticsEnabledOnSelectedDs } from '../../utils/dataSourceUtil'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'

export type Step = {
  text: string
  component: React.ReactElement
  hideStep?: boolean
  proceedNextStepAllowed?: boolean
  btnBackText?: string
  btnNextText?: string
  btnCancelText?: string
  isBtnBackDisabled?: boolean
  isBtnNextDisabled?: boolean
  isBtnCancelDisabled?: boolean
  btnCancelResetSteps?: boolean
  showBtnBack?: boolean
  onBtnBackClick?: () => void
  onBtnNextClick?: () => void
  onBtnCancelClick?: () => void
  renderBtnSubmit?: () => React.ReactElement
}

interface IProps {
  steps: Step[]
  className?: string
  isFoldersInvalid?: boolean
  currentStep: number
  isSaveProcessing: boolean
  isOfflineScan?: boolean
  deleteDataSourceById: (id: string) => void
  setCurrentStep: (step: number) => void
  featureFlags: FeatureFlags
}

export const DATA_SOURCES_WITH_SCAN_SETTINGS = [
  DATA_SOURCE_TYPES.slack,
  DATA_SOURCE_TYPES.teams,
  DATA_SOURCE_TYPES.jira,
  DATA_SOURCE_TYPES.aws,
  DATA_SOURCE_TYPES.googleCloudStorage,
  DATA_SOURCE_TYPES.azureBlob,
  DATA_SOURCE_TYPES.outLook,
  DATA_SOURCE_TYPES.oneDrive,
  DATA_SOURCE_TYPES.gmail,
  DATA_SOURCE_TYPES.gdrive,
  DATA_SOURCE_TYPES.sharePoint,
  DATA_SOURCE_TYPES.box,
  DATA_SOURCE_TYPES.smb,
  DATA_SOURCE_TYPES.bigQuery,
  DATA_SOURCE_TYPES.looker,
  DATA_SOURCE_TYPES.dropbox
]

export const INIT_STEP = 1

const DataSourceSettings = ({
  steps,
  isFoldersInvalid = false,
  className = '',
  currentStep,
  isSaveProcessing,
  isOfflineScan = false,
  // featureFlags,
  deleteDataSourceById,
  setCurrentStep,
  featureFlags
}: IProps): React.ReactElement => {
  const intl = useIntl()
  const [allowEditDatasource, setAllowEditDatasource] = useState(false)

  const activeStepSettings = steps[currentStep - 1]
  const {
    onBtnBackClick,
    onBtnNextClick,
    onBtnCancelClick,
    component: activeComponent,
    showBtnBack,
    isBtnCancelDisabled,
    isBtnNextDisabled,
    isBtnBackDisabled,
    btnNextText,
    btnBackText,
    btnCancelText,
    btnCancelResetSteps = true,
    renderBtnSubmit
  } = activeStepSettings

  const handleGoBack = () => {
    if (currentStep > 1) {
      setPendingStep(null)
      setCurrentStep(currentStep - 1)
      onBtnBackClick && onBtnBackClick()
    }
  }

  const [pendingStep, setPendingStep] = useState<number | null>(null)
  const proceedNextStepAllowed =
    steps[currentStep - 1].proceedNextStepAllowed !== undefined
      ? steps[currentStep - 1].proceedNextStepAllowed
      : true

  useEffect(() => {
    if (pendingStep && proceedNextStepAllowed) {
      setCurrentStep(currentStep + 1)
      setPendingStep(null)
    }
  }, [pendingStep, currentStep, proceedNextStepAllowed, setCurrentStep])

  const handleGoNext = (event) => {
    event?.preventDefault()
    onBtnNextClick && onBtnNextClick()

    if (!proceedNextStepAllowed) {
      setPendingStep(currentStep)
    }

    if (currentStep < steps.length && proceedNextStepAllowed) {
      setCurrentStep(currentStep + 1)
    }
  }

  const handleCancel = () => {
    btnCancelResetSteps && setCurrentStep(INIT_STEP)
    onBtnCancelClick && onBtnCancelClick()
  }

  const context = useUrlContext()
  const dataSourceID: string = context.sourceId
  const dataSourceType = context.queryParams[DATA_SOURCE_TYPE] as DATA_SOURCE_TYPES
  const isStructDs = STRUCTURED_DATASOURCES.includes(dataSourceType)
  const isScanSettingsStep = DATA_SOURCES_WITH_SCAN_SETTINGS.includes(dataSourceType)
  const isCosmosPostgres = dataSourceType === DATA_SOURCE_TYPES.cosmosPostgres
  const isEditEnabled = context.queryParams.edit === 'true'
  const showDocAnalyticsStep =
    isDocAnalyticsEnabledOnSelectedDs(dataSourceType) && featureFlags?.[FEATURE_FLAGS.DOC_ANALYTICS]

  useEffect(() => {
    setAllowEditDatasource(isEditEnabled)
  }, [isEditEnabled])

  const [isDeleteModalOpened, toggleDeleteModal] = useState(false)

  const onConfirmDeleteDS = () => {
    deleteDataSourceById(dataSourceID)
    changeUrl(`${URL_DATA_SOURCES}`)
  }

  const handleEditDatasource = () => {
    addToUrlQuery({ [ACTION_TYPE.edit]: !allowEditDatasource })
  }

  const tabs: StepTab[] = [
    {
      isActive: currentStep === 1,
      content: intl.formatMessage({ id: 'dataSources.info.basic' }),
      onClick: () => setCurrentStep(1)
    }
  ]

  if (steps.length > 1) {
    tabs.push({
      isActive: currentStep === 2,
      content: intl.formatMessage({ id: 'dataSources.info.connection' }),
      onClick: () => setCurrentStep(2)
    })
  }

  if (!isOfflineScan && (isScanSettingsStep || isStructDs)) {
    tabs.push({
      isActive: currentStep === 3,
      content: intl.formatMessage({
        id: isScanSettingsStep
          ? 'dataSources.info.scanSettings'
          : isCosmosPostgres
          ? 'dataSources.info.schemas'
          : 'dataSources.info.databases'
      }),
      onClick: () => setCurrentStep(3)
    })
  }

  if (showDocAnalyticsStep) {
    tabs.push({
      isActive: currentStep === 4,
      content: intl.formatMessage({
        id: 'dataSourceSettings.step.docAnalytics'
      }),
      onClick: () => setCurrentStep(4)
    })
  }

  const cancelText = btnCancelText
    ? btnCancelText
    : dataSourceID
    ? intl.formatMessage({ id: 'dataSourceSettings.btn.discard' })
    : intl.formatMessage({ id: 'dataSourceSettings.btn.cancel' })

  return (
    <div className={`data-source-settings ${className}`} data-test-id="datasource-settings">
      {dataSourceID && (
        <StepsTabs
          tabs={tabs}
          onEditClick={handleEditDatasource}
          onDeleteClick={() => toggleDeleteModal(true)}
        />
      )}
      <div className="content-wrapper">
        {!dataSourceID && <StepsBar steps={steps} activeStep={currentStep} />}
        {activeComponent}
        <ControlPane
          showBackButton={currentStep > 1 && showBtnBack}
          isBackDisabled={isBtnBackDisabled}
          isNextDisabled={isFoldersInvalid || isBtnNextDisabled || isSaveProcessing}
          isCancelDisabled={isBtnCancelDisabled}
          onGoBack={handleGoBack}
          onGoForward={handleGoNext}
          onCancel={handleCancel}
          submitText={btnNextText}
          backText={btnBackText}
          cancelText={cancelText}
          renderBtnSubmit={renderBtnSubmit}
        />
        <ModalDeleteDataSource
          open={isDeleteModalOpened}
          onClose={() => toggleDeleteModal(false)}
          onConfirm={onConfirmDeleteDS}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  isSaveProcessing:
    getActionState(ACTION_DATA_SOURCE_UPDATE) === STATES.pending ||
    getActionState(ACTION_DATA_SOURCE_PARTIAL_UPDATE) === STATES.pending,
  featureFlags: state.featureFlags
})

const mapDispatchToProps = { deleteDataSourceById }

export default connect(mapStateToProps, mapDispatchToProps)(DataSourceSettings)
