import {
  DatabaseSummary,
  DatabaseSummaryParams,
  DataSourceSummaryParams,
  TableSummary,
  TableSummaryParams,
  DataSourceSummary,
  EntitySummaryParams,
  EntityWidgets,
  AlertSummaryParams,
  AlertSummary,
  DriveSummaryCountParams,
  GDriveDriveSummary,
  GDriveDriveSummaryQueryParams,
  GenericProjectSummary,
  GenericProjectSummaryQueryParams,
  FetchJiraPIITicketsCountParams,
  AWSS3SummaryCountParams,
  EntityAttribute,
  EntityLabels
} from './summarySlice'
import {
  ALERT_ID,
  DATA_SOURCE_ID,
  EMPTY_CONTENT,
  ENTITY_ID,
  GRAPHQL_CLASSIFICATION_TYPE
} from '../../constants'

import { parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import type {
  AWSS3Summary,
  BucketSummaryQueryParams,
  UserEntity
} from '../../services/api/apiTypes'
import { gql } from 'graphql-request'

export const queryTableDetails = (params: TableSummaryParams): string => gql`
  {
    tables(id: "${params.tableId}") {
      edges {
        node {
          id
          name
          size
          rowCount
          isReviewed
          changeType
          schema {
           edges {
              node {
                id
              }
            }
          }
          datasource {
            edges {
              node {
                createdBy
              }
            }
          }
          database {
            edges {
              node {
                id
              }
            }
          }
          columns {
            count
          }
          columnsSensitive: columns(classification: ${GRAPHQL_CLASSIFICATION_TYPE.PII}) {
            count
          }
        }
      }
    }
  }
`

// TODO: add type for graphql response
export const mapQueryTableDetails = (raw: any): TableSummary => {
  try {
    return {
      tableId: raw.tables.edges[0].node.id,
      tableName: raw.tables.edges[0].node.name,
      isReviewed: raw.tables.edges[0].node.isReviewed,
      schemaId: raw.tables.edges[0]?.node?.schema?.edges[0]?.node.id,
      size: raw.tables.edges[0].node.size,
      rowsCount: raw.tables.edges[0].node.rowCount,
      changeType: raw.tables.edges[0].node.changeType,
      owner: raw.tables.edges[0].node.datasource.edges[0]?.node?.createdBy,
      databaseId: raw.tables?.edges[0]?.node?.database?.edges[0]?.node?.id || '',
      columnsCount: raw.tables.edges[0].node.columns.count,
      columnsSensitiveCount: raw.tables.edges[0].node.columnsSensitive.count
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryDatabaseDetails = (params: DatabaseSummaryParams): string => gql`
  {
    databases(id: "${params.databaseId}") {
      edges {
        node {
          id
          name
          size
          schemas {
            count
          }
          tables {
            count
          }
          columns {
            count
          }
          columnsSensitive: columns(classification: ${GRAPHQL_CLASSIFICATION_TYPE.PII}) {
            count
          }
          datasource {
            edges {
              node {
                createdBy
              }
            }
          }
          notificationConfiguration {
            enabled
            databaseOwners
            acked
          }
        }
      }
    }
  }
`

// TODO: add type for graphql response
export const mapQueryDatabaseDetails = (raw: any): DatabaseSummary => ({
  databaseId: raw.databases.edges[0].node.id,
  databaseName: raw.databases.edges[0].node.name,
  size: raw.databases.edges[0].node.size,
  owner: raw.databases.edges[0].node.datasource.edges[0]?.node?.createdBy,
  schemasCount: raw.databases.edges[0].node.schemas.count,
  tablesCount: raw.databases.edges[0].node.tables.count,
  columnsCount: raw.databases.edges[0].node.columns.count,
  columnsSensitiveCount: raw.databases.edges[0].node.columnsSensitive.count,
  notificationEnabled: raw.databases.edges[0].node.notificationConfiguration?.enabled,
  notificationOwners: raw.databases.edges[0].node.notificationConfiguration?.databaseOwners || [],
  notificationAcknowledged: raw.databases.edges[0].node.notificationConfiguration?.acked
})

export const queryDataSourceDetails = (params: DataSourceSummaryParams): string => gql`
  {
    datasources(id: "${params.datasourceId}") {
      edges {
        node {
          id
          name
          location
          createdBy
          size
          registeredOn
          lastSyncedTime
        }
      }
    }
  }
`

// TODO: add type for graphql response
export const mapQueryDataSourceDetails = (raw: any): DataSourceSummary => {
  try {
    return {
      dataSourceId: raw.datasources.edges[0]?.node?.id,
      dataSourceName: raw.datasources.edges[0]?.node?.name,
      location: raw.datasources.edges[0]?.node?.location,
      owner: raw.datasources.edges[0]?.node?.createdBy,
      size: raw.datasources.edges[0]?.node?.size,
      lastUpdated:
        raw.datasources.edges[0]?.node?.lastSyncedTime ||
        raw.datasources.edges[0]?.node?.registeredOn
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryEntityWidgets = (params: EntitySummaryParams): string => {
  let paramStr = ''
  const { [ENTITY_ID]: entityId, [DATA_SOURCE_ID]: datasourceId } = params

  if (entityId) {
    paramStr += `id:"${entityId}"`
  }
  if (datasourceId) {
    paramStr += `,datasourceId:"${datasourceId}"`
  }

  if (paramStr) {
    paramStr = '(' + paramStr + ')'
  }
  return gql`
  {
    userEntities${paramStr} {
      edges {
        node {
          attributesCount
          policyViolationCount
          datasources(first: 1) {
            count
          }
          objects(first:1){
            count
          }
        }
      }
    }
  }
`
}
// TODO: add type for graphql response
export const mapQueryEntityWidgets = (raw: any): EntityWidgets => ({
  attributesCount: raw.userEntities.edges[0].node.attributesCount || 0,
  violationsCount: raw.userEntities.edges[0].node.policyViolationCount || 0,
  dataSourcesCount: raw.userEntities.edges[0].node.datasources?.count || 0,
  objectsCount: raw.userEntities.edges[0].node.objects?.count || 0
})

export const queryDataSourceEntitiesCount = (params: DriveSummaryCountParams): string => {
  const { ...filters } = params.filters
  const filterString = filters.filter ? parameterizeArrayofObjects(filters.filter) : ''
  const paramString = filterString ? `, filter: ${filterString}` : ''
  return gql`
  {
    userEntities(datasourceId: "${params[DATA_SOURCE_ID]}"${paramString}) {
      count
    }
  }
`
}

export const mapQueryDataSourceEntitiesCount = (raw: any): { total: number } => {
  let total = 0
  if (raw && raw.userEntities) {
    total = raw.userEntities.count || 0
  }
  return {
    total
  }
}

export const queryDataSourceRiskyFilesCount = (params: DriveSummaryCountParams): string => {
  const { ...filters } = params.filters
  const filterString = filters.filter ? parameterizeArrayofObjects(filters.filter) : ''
  const booleanFilterString = filters.booleanFilter
    ? parameterizeArrayofObjects(filters.booleanFilter)
    : ''
  const paramString = filterString ? `, filter: ${filterString}` : ''
  const booleanParamString = booleanFilterString ? `, booleanFilter: ${booleanFilterString}` : ''

  return gql`
  {
    objects(datasourceIds: "${params[DATA_SOURCE_ID]}" ${paramString} ${booleanParamString}) {
      count
    }
  }
`
}

export const mapQueryDataSourceRiskyFilesCount = (raw: any): { total: number } => {
  let total = 0
  if (raw && raw.objects) {
    total = raw.objects.count || 0
  }
  return {
    total
  }
}

export const queryAlertDetails = (params: AlertSummaryParams): string => {
  return gql`
  {
    alert(first: 1, id: "${params[ALERT_ID]}") {
      edges {
        node {
          id
          name
          status
          severity
          subAlertAssignees(limit: 2) {
            totalCount
            assignees
          }
          datasource {
            edges {
              node {
                id
                type
                name
              }
            }
          }
          policy {
            edges {
              node {
                description
                policyType {
                  edges {
                    node {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
}

export const mapQueryAlertDetails = (raw: any): AlertSummary => {
  const alert = raw.alert.edges[0].node

  return {
    id: alert.id,
    name: alert.name,
    status: alert.status,
    severity: alert.severity,
    assignees: alert.subAlertAssignees.assignees || [],
    assigneesCount: alert.subAlertAssignees.totalCount || [],
    dataSourceId: alert.datasource.edges[0].node.id,
    dataSourceName: alert.datasource.edges[0].node.name,
    dataSourceType: alert.datasource.edges[0].node.type,
    policyName: alert.policy?.edges[0].node.policyType?.edges[0].node.name || '',
    description: alert.policy?.edges[0].node.description || ''
  }
}

export const queryGDriveDriveSummaryData = (params: GDriveDriveSummaryQueryParams): string => {
  let filters = ''
  const filterString = parameterizeArrayofObjects(params.filters?.filter || [])
  if (filterString) {
    filters += `, filter:${filterString}`
  }

  // adding filter to connection as duplicate datasource can create issues for non mongo ids (objects ids)
  return gql`
  {
    drive(datasourceId: "${params.datasourceId}"${filters}){
      edges{
        node{
          driveName
          owner
          size
          documentsCount
        }
      }
    }
  }
`
}

export const mapQueryGDriveDriveSummaryData = (raw: any): { data: GDriveDriveSummary } => {
  let data: GDriveDriveSummary = {
    driveOwner: EMPTY_CONTENT,
    driveName: EMPTY_CONTENT,
    driveSize: 0,
    filesCount: 0,
    membersCount: 0,
    groupsCount: 0
  }

  if (raw && raw.drive && raw.drive.edges && Array.isArray(raw.drive.edges)) {
    const summary = raw.drive.edges[0].node
    if (summary) {
      data = {
        ...data,
        driveOwner: summary.owner,
        driveName: summary.driveName,
        driveSize: summary.size,
        filesCount: summary.documentsCount || 0
      }
    }
  }

  return { data }
}

export const queryProjectsSummaryWidgetData = (datasourceId: string): string => {
  return gql`
  {
    datasources(id: "${datasourceId}") {
      edges {
        node {
          apiCount
          projects {
            count
          }
        }
      }
    }
  }
`
}

export const mapQueryProjectsSummaryWidgetData = (
  raw: any
): {
  projectsCount: number
  apiCount: number
} => {
  let apiCount = 0
  let projectsCount = 0
  if (raw && raw.datasources) {
    if (raw.datasources.edges && Array.isArray(raw.datasources.edges)) {
      const node = raw.datasources.edges[0].node
      apiCount = node.apiCount || 0
      if (node.projects) {
        projectsCount = node.projects.count || 0
      }
    }
  }
  return { projectsCount, apiCount }
}

export const queryJiraTicketsWithPIICount = ({
  datasourceId,
  filters
}: FetchJiraPIITicketsCountParams): string => {
  const filterString = filters && filters.filter ? parameterizeArrayofObjects(filters.filter) : ''
  const paramString = filterString ? `, filter: ${filterString}` : ''

  return gql`
  {
    ticket(datasourceId: "${datasourceId}", booleanFilter: [{key: CONTAINS_PII_DATA, value: true}] ${paramString}) {
      count
    }
  }
`
}

export const mapQueryJiraTicketsWithPIICount = (raw: any): { ticketsCount: number } => {
  try {
    return {
      ticketsCount: raw.ticket.count
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryJiraProjectsCount = (datasourceId: string): string => {
  return gql`
  {
    ticketStoreProject(datasourceId: "${datasourceId}") {
      count
    }
  }
`
}

export const mapQueryJiraProjectsCount = (raw: any): { projectsCount: number } => {
  try {
    return {
      projectsCount: raw.ticketStoreProject.count
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryGenericDataSourceSummaryById = (datasourceId: string): string => {
  return gql`
  {
    datasources(id: "${datasourceId}"){
      count
      edges{
        node{
          type
          location
        }
      }
    }
  }
  `
}

export const mapQueryGenericDataSourceSummaryById = (
  raw: any
): { location: string; type: string } => {
  let location = ''
  let type = ''
  if (raw && raw.datasources && raw.datasources.edges && Array.isArray(raw.datasources.edges)) {
    const info = raw.datasources.edges[0]
    if (info && info.node) {
      location = info.node.location || ''
      type = info.node.type
    }
  }

  return {
    location,
    type
  }
}

export const queryGenericProjectSummaryData = (
  params: GenericProjectSummaryQueryParams
): string => {
  const { projectId } = params
  return gql`
  {
    projects(id: "${projectId}") {
      edges {
        node {
          name
          default
          owner {
            name
          }
          objects {
            count
          }
        }
      }
    }
  }

  `
}

export const mapQueryGenericProjectSummaryData = (raw: any): { data: GenericProjectSummary } => {
  let data: GenericProjectSummary = {
    projectName: '',
    projectOwner: '',
    objectsCount: 0,
    isDefaultProject: false
  }
  if (raw && raw.projects && raw.projects.edges && Array.isArray(raw.projects.edges)) {
    const summary = raw.projects.edges[0].node
    if (summary.name) {
      data = {
        projectName: summary.name || ''
      }
    }
    if (summary.owner) {
      data = {
        ...data,
        projectOwner: summary.owner.name || ''
      }
    }
    if (summary.objects) {
      data = {
        ...data,
        objectsCount: summary.objects.count || 0
      }
    }
    if (summary.default) {
      data = {
        ...data,
        isDefaultProject: summary.default
      }
    }
  }
  return { data }
}

export const queryJiraSummaryById = (datasourceId: string): string => {
  return gql`
  {
    ticket(datasourceId: "${datasourceId}") {
      count
    }
    datasources(id: "${datasourceId}"){
      edges{
        node{
          location
        }
      }
    }
  }
  `
}

export const mapQueryJiraSummaryById = (raw: any): { location: string; ticketsCount: number } => {
  try {
    return {
      location: raw.datasources.edges[0].node.location || '',
      ticketsCount: raw.ticket.count || 0
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAWSS3SummaryCounts = ({ datasourceId }: AWSS3SummaryCountParams): string => {
  return gql`
  {
    datasources(id: "${datasourceId}"){
      edges {
        node {
          buckets {
            count
          }
          objects(booleanFilter: {key: IS_SENSITIVE, value: true}){
            count
          }
          policies{
            count
          }
        }
      }
    }
  }
  `
}

export const mapQueryAWSS3BucketsCount = (raw: any): AWSS3Summary => {
  try {
    return {
      bucketsCount: raw.datasources.edges[0].node.buckets.count,
      objectsCount: raw.datasources.edges[0].node.objects.count,
      policiesCount: raw.datasources.edges[0].node.policies.count
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAwsSummaryWidget = (params: BucketSummaryQueryParams): string => {
  const { datasourceId, bucketId } = params
  return gql`
  {
    bucket(filter: [{key: DATASOURCE_IDS, values: [${JSON.stringify(
      datasourceId
    )}] }, { key: NAME, values: [${JSON.stringify(bucketId)}]}]){
        edges{
          node{
            name
            type
            size
            displayName
            ownerName
            totalFiles: objects{
              count
            }
          }
        }
      }
  }
  `
}

export const querySlackScannedChannelsCount = (datasourceId: string): string => gql`
{
  channel(datasourceIds: "${datasourceId}" , booleanFilter: [{key:IS_SENSITIVE,value:true}]){
    count
  }
}
`

export const mapQuerySlackScannedChannelsCount = (raw: any): number => {
  return raw.channel?.count || 0
}

export const queryTeamsCount = (datasourceId: string): string => gql`
  {
    msTeams(filter: { key: DATASOURCE_IDS, values: "${datasourceId}" }) {
      count
    }
  }
`
export const mapQueryTeamsCount = (raw: any): number => {
  return raw.msTeams?.count || 0
}

export const mapQueryAwsSummaryWidget = (
  raw: any
): {
  bucketCount: number
  bucketName: string
  bucketOwner: string
  bucketAccess: string
  displayName: string
} => {
  try {
    const bucketInfo = raw.bucket.edges[0]?.node
    return {
      bucketCount: bucketInfo?.size || 0,
      bucketName: bucketInfo?.name,
      bucketOwner: bucketInfo?.ownerName,
      bucketAccess: bucketInfo?.type,
      displayName: bucketInfo?.displayName || ''
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryEntityAttributes = (entityId: string): string => gql`
  {
    userEntities(id: "${entityId}") {
      edges {
        node {
          id
          dynamicPiiData
        }
      }
    }
  }
`
export const mapQueryEntityAttributes = (raw: any): EntityAttribute[] => {
  try {
    const parsedAttributes = JSON.parse(raw.userEntities.edges[0].node.dynamicPiiData)

    const attributes = Object.entries(parsedAttributes).map(([key, value]) => {
      const values = value as string[]
      return { internalName: key, value: values[0] }
    })

    return attributes
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const queryEntityTypesLables = (entityId: string): string => gql`
  {
    userEntities(id: "${entityId}") {
      edges{
        node{
          type {
            edges {
              node {
                name
              }
            }
          }
          legalHoldStatus {
            hasHoldExpired
            isOnLegalHold
          }
          lastModifiedTime
          labels{
            edges{
              node{
                labelDisplayName
              }
            }
          }
        }
      }
    }
  }
`
export const mapQueryEntityTypesLables = (raw: any): EntityLabels => {
  try {
    const entity = raw.userEntities.edges[0].node

    const labels = entity.labels.edges.map(({ node }) => node.labelDisplayName)
    const types = entity.type.edges.map(({ node }) => node.name)
    const isOnLegalHold = entity.legalHoldStatus?.isOnLegalHold
    const hasHoldExpired = entity.legalHoldStatus?.hasHoldExpired
    const lastModifiedTime = entity?.lastModifiedTime

    return { labels, types, isOnLegalHold, hasHoldExpired, lastModifiedTime }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getEntitySummary = (params: EntitySummaryParams): string => {
  return gql`
    {
      userEntities(
        id: "${params[ENTITY_ID]}"
        ${params[DATA_SOURCE_ID] ? `, datasourceId: "${params[DATA_SOURCE_ID]}"` : ''}
      ) {
        edges {
          node {
            id
            name
            riskStatus
            state
            documentsCount
            deletedUserEntityReferences
          }
        }
    	}
    }
  `
}

export const mapEntitySummary = (raw: any): UserEntity => {
  try {
    const entity: UserEntity = {
      id: raw.userEntities.edges[0].node.id,
      name: raw.userEntities.edges[0].node.name,
      risky: raw.userEntities.edges[0].node.riskStatus ?? false,
      state: raw.userEntities.edges[0].node.state ?? [],
      documentCount: raw.userEntities.edges[0].node.documentsCount ?? 0,
      deletedUserEntityReferences: raw.userEntities.edges[0].node.deletedUserEntityReferences ?? []
    }

    return entity
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw error
  }
}
